import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import ReasonReducer, {
  IReasonState,
  defaultState,
  duckActions,
} from 'src/ducks/reason.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    ReasonReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IReasonContext = ReturnType<typeof useReducer>;

const ReasonContext = createContext<Partial<IReasonContext>>({
  state: defaultState,
}) as React.Context<IReasonContext>;

type IProps = {
  state?: Partial<IReasonState>;
};

const ReasonProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <ReasonContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </ReasonContext.Provider>
  );
};

const useReasonContext = () => useContext(ReasonContext);

export type IUseReasonContext = ReturnType<typeof useReasonContext>;

export { ReasonContext, ReasonProvider, useReasonContext };
