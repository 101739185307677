import React from 'react';
import {
  ORGDataTable,
  IORGDataTableColumn,
  IORGDataTableQueryState,
  ATMDropdown,
  IATMTabProps,
  ATMMenu,
  ATMTab,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { IContractorFirm } from 'src/models/contractor-firm.model';
import ContractorFirmDelete from '../contractor-firm-delete/contractor-firm-delete.component';
import ContractorFirmEdit from '../contractor-firm-edit/contractor-firm-edit.component';
import ContractorFirmActivate from '../contractor-firm-activate/contractor-firm-activate.component';
import Tabs from 'src/components/atoms/tabs/tabs.component';
import style from '../contractor-firm.module.scss';

type IProps = {
  data: IContractorFirm[];
  loading: boolean;
  handleFetch: (params: IORGDataTableQueryState) => void;
  currentTab: number;
};

const ContractorFirmListView: React.FC<IProps> = ({
  data,
  loading,
  handleFetch,
  currentTab,
}) => {
  const columns: IORGDataTableColumn<IContractorFirm>[] = [
    {
      index: 'contracShortNm',
      title: Lang.LBL_CONTRACTOR_FIRM_SHORT_NAME,
    },
    {
      index: 'fullName',
      title: Lang.LBL_CONTRACTOR_FIRM_FULL_NAME,
    },
    {
      index: 'contactName',
      title: Lang.LBL_CONTRACTOR_FIRM_CONTACT,
    },
    {
      index: 'areaCode',
      title: Lang.LBL_CONTRACTOR_FIRM_AREA_CODE,
    },
    {
      index: 'phoneNumber',
      title: Lang.LBL_CONTRACTOR_FIRM_PHONE_NUMBER,
    },
    {
      index: 'emailAddress',
      title: Lang.LBL_CONTRACTOR_FIRM_EMAIL_ADDRESS,
      sortable: false,
    },
    {
      index: 'action',
      sortable: false,
      title: '',
      width: '2%',
      cellProps: {
        textAlign: 'right',
      },
      render: (_, value) => {
        return (
          <div>
            <ATMDropdown size="small" icon="ellipsis horizontal">
              <ATMDropdown.Menu>
                {currentTab === 0 ? (
                  <>
                    <ContractorFirmEdit data={value} />
                    <ContractorFirmDelete data={value} />
                  </>
                ) : (
                  <ContractorFirmActivate data={value} />
                )}
              </ATMDropdown.Menu>
            </ATMDropdown>
          </div>
        );
      },
    },
  ];

  const table = (
    <>
      <ORGDataTable
        columns={columns}
        sortable
        data={
          currentTab === 0
            ? data.filter((value) => value.activeFlag)
            : data.filter((value) => value.activeFlag === false)
        }
        onChange={handleFetch}
        loading={loading}
        celled={false}
        showPagination={false}
        counter
      />
    </>
  );
  const panes: IATMTabProps['panes'] = [
    {
      menuItem: (
        <ATMMenu.Item key="0">
          {Lang.LBL_ACTIVE_FIRMS} (
          {data.filter((value) => value.activeFlag).length})
        </ATMMenu.Item>
      ),
      render: () => <ATMTab.Pane attached={false}>{table}</ATMTab.Pane>,
    },
    {
      menuItem: (
        <ATMMenu.Item key="1">
          {Lang.LBL_INACTIVE_FIRMS} (
          {data.filter((value) => value.activeFlag === false).length})
        </ATMMenu.Item>
      ),
      render: () => <ATMTab.Pane attached={false}>{table}</ATMTab.Pane>,
    },
  ];

  return (
    <div className={style.mainContainerPosition}>
      <div className={style.headerTabs}>
        <Tabs
          className={style.table}
          name="contractorFirm"
          menu={{ pointing: true }}
          panes={panes}
          activeIndex={0}
        />
      </div>
    </div>
  );
};

export default ContractorFirmListView;
