import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/cancel-type.service';
import { ICancelType } from 'src/models/cancel-type.model';

export const cancelTypeActionTypes = {
  CANCEL_TYPE_LIST_READ: 'CANCEL_TYPE_LIST_READ',
} as const;

export const duckActions = {
  listGET: {
    type: cancelTypeActionTypes.CANCEL_TYPE_LIST_READ,
    service: services.listGET,
  },
};

export type ICancelTypeAsync = typeof duckActions;

export interface ICancelTypeState
  extends ICommonState<typeof cancelTypeActionTypes> {
  data?: ICancelType;
  list: ICancelType[];
  total: number;
}

export const defaultState: ICancelTypeState = {
  status: {},
  list: [],
  total: 0,
};

const CancelTypeReducer = (
  state: ICancelTypeState,
  action: IReducerAction<ICancelTypeAsync>
): ICancelTypeState => {
  switch (action.type) {
    case cancelTypeActionTypes.CANCEL_TYPE_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    default: {
      return state;
    }
  }
};

export default CancelTypeReducer;
