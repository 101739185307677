import {
  isValidAlphaNumericField,
  isValidAlphanumericWithSpecialCharField,
  ValidationErrorMessage,
} from 'src/helpers/field-validation.helper';
import yup from 'src/libraries/validator.library';
import { TestContext } from 'yup';

export const checkOutageRequestFilter = (_value, context: TestContext) => {
  const {
    path,
    parent: { lerShortDescCatId, lerShortDescId },
  } = context;

  if (lerShortDescCatId && !lerShortDescId) {
    if (path.includes('lerShortDescId')) {
      return context.createError({
        message: 'This field is required',
      });
    }
  }
  return true;
};

export const OutageDateShape = {
  requestId: yup.number().required(),
  version_no: yup.string().required(),
  // dataChngFlag: yup.string().required(),
  reqStatId: yup.number().required(),
  startTm: yup.date().required(),
  stopTm: yup.date().required(),
  updatedAt: yup.date().required(),
  updatedBy: yup.string().required(),
  significantCostInd: yup.string().nullable(),
  actualStrtTm: yup.date().nullable(),
  actualStopTm: yup.date().nullable(),
  actualSwitchInTm: yup.date().nullable(),
  actualSwitchOutTm: yup.date().nullable(),
};

export const OutageDateSchema = yup.object(OutageDateShape);

export const OutageEmsDataShape = {
  requestId: yup.number().required(),
  version_no: yup.string().required(),
  emsId: yup.string().required(),
  top: yup.string().required(),
  station: yup.string().required(),
  busName: yup.string().required(),
  equipmentName: yup.string().required(),
  equipmentType: yup.number().required(),
  position: yup.boolean().required(),
  updatedAt: yup.date().required(),
  updatedBy: yup.string().required(),
};

export const OutageEmsDataSchema = yup.object(OutageEmsDataShape);

export const OutageRequestListItemShape = {
  requestId: yup.number().required(),
  requestStat: yup.string().nullable(),
  changeReqStat: yup.string().nullable(),
  requestorId: yup.string().required(),
  crewId: yup.string().nullable(),
  schedulerAssignId: yup.string().nullable(),
  woNbr: yup.string().nullable(),
  cnclNoteId: yup.number().nullable(),
  isoWorkKindId: yup.string().nullable(),
  recalTm: yup.number().nullable(),
  wrkDesc: yup.string().nullable(),
  omsUseFacilityModelInd: yup.string().nullable(),
  outOfServiceRasSpsInd: yup.string().nullable(),
  redundancyRasSpsInd: yup.string().nullable(),
  protectionZoneInd: yup.string().nullable(),
  outageEmsIccpInd: yup.string().nullable(),
  switchOutMin: yup.number().nullable(),
  switchInMin: yup.number().nullable(),
  outageFacility: yup
    .object({
      outgFacId: yup.number().required(),
      substationId: yup.string().nullable(),
      outgTypId: yup.number().nullable(),
      voltId: yup.number().nullable(),
      facility: yup
        .object({
          outgFacNm: yup.string().required(),
          outgFacId: yup.number().required(),
          facTypId: yup.number().required(),
          voltId: yup.number().required(),
          facTyp: yup
            .object({
              facTypId: yup.number().required(),
              facTypNm: yup.string().required(),
            })
            .required(),
          volt: yup
            .object({
              voltId: yup.number().required(),
              voltNm: yup.string().required(),
            })
            .nullable(),
        })
        .required(),
      substation: yup
        .object({
          substationId: yup.string().required(),
          name: yup.string().required(),
        })
        .nullable(),
      outageType: yup
        .object({
          outageTypeId: yup.number().required(),
          outageTypeDesc: yup.string().required(),
        })
        .nullable(),
      voltage: yup
        .object({
          voltId: yup.number().required(),
          voltNm: yup.string().required(),
        })
        .nullable(),
    })
    .required(),
  outageDates: yup.array(yup.object(OutageDateShape).required()).required(),
  outageDateStart: yup.object(OutageDateShape),
  outageDateStop: yup.object(OutageDateShape),
  caisoTrs: yup.array(
    yup
      .object({
        requestId: yup.number().required(),
        caisoId: yup.string().required(),
      })
      .required()
  ),
  shortDescriptions: yup
    .array()
    .of(
      yup
        .object({
          lerShortDescId: yup.number().required(),
          lerShortDescCatId: yup.number().required(),
          description: yup
            .object({
              descId: yup.number().required(),
              shortDesc: yup.string().required(),
            })
            .required(),
          category: yup
            .object({
              catId: yup.number().required(),
              shortDescCat: yup.string().required(),
            })
            .required(),
        })
        .required()
    )
    .required(),
  requestor: yup
    .object({
      empno: yup.string().required(),
      firstName: yup.string().required(),
      lastName: yup.string().required(),
    })
    .nullable(),
  crew: yup
    .object({
      empno: yup.string().required(),
      firstName: yup.string().required(),
      lastName: yup.string().required(),
    })
    .nullable(),
  schedulerAssign: yup
    .object({
      empno: yup.string().required(),
      firstName: yup.string().required(),
      lastName: yup.string().required(),
    })
    .nullable(),
  cancelNote: yup
    .object({
      noteId: yup.number().required(),
      noteTxt: yup.string().required(),
    })
    .nullable(),
  startDate: yup.date().nullable(),
  endDate: yup.date().nullable(),
  days: yup.number().required(),
  emsDatas: yup.array(yup.object(OutageEmsDataShape).required()).nullable(),
};

export const OutageRequestListItemSchema = yup.object(
  OutageRequestListItemShape
);

export const OutageRequestListSchema = yup.object({
  count: yup.number(),
  rows: yup.array().of(OutageRequestListItemSchema.clone()),
});

export const OutageRequestFilterShape = {
  requestIds: yup.array().of(yup.number()),
  requestorName: yup.string(),
  requestorId: yup.string(),
  requestStat: yup.string(),
  changeReqStat: yup.array().of(yup.string().nullable()).nullable(),
  lerShortDescId: yup.string(),
  // .test(checkOutageRequestFilter)
  // .typeError('This field is required'),
  lerShortDescCatId: yup.string(),
  facilityType: yup.string(),
  facilityName: yup
    .string()
    .default(undefined)
    .test(
      'isValid',
      `Invalid facility name. ${ValidationErrorMessage.ALPHA_NUMERIC_WITH_SPECIAL_CHAR}`,
      isValidAlphanumericWithSpecialCharField
    ),
  facTypId: yup.number(),
  outgTypId: yup.number(),
  voltId: yup.number(),
  crewName: yup.string(),
  dateRange: yup.array().of(yup.date()),
  dateStart: yup.date(),
  dateEnd: yup.date(),
  cancelNoteTxt: yup
    .string()
    .default(undefined)
    .test(
      'isValid',
      `Invalid cancel notes. ${ValidationErrorMessage.ALPHA_NUMERIC_WITH_SPECIAL_CHAR}`,
      isValidAlphanumericWithSpecialCharField
    ),
  woNbr: yup
    .string()
    .default(undefined)
    .test(
      'isValid',
      `Invalid work order number. ${ValidationErrorMessage.ALPHA_NUMERIC}`,
      isValidAlphaNumericField
    ),
  substationId: yup.string(),
  hasCancelNote: yup.boolean(),
  emsCriteria: yup.array().of(yup.mixed()),
  updatedBy: yup.string(),
  or: yup.array().of(
    yup.object({
      requestorId: yup.string(),
      updatedBy: yup.string(),
      supvId: yup.string(),
    })
  ),
  sortOrderNumber: yup
    .string()
    .default(undefined)
    .test(
      'isValid',
      `Invalid work order number. ${ValidationErrorMessage.ALPHA_NUMERIC}`,
      isValidAlphaNumericField
    ),
  rejectReasonId: yup.string(),
  cancelTypeId: yup.string(),
  changeRequestReasonId: yup.string(),
};

export const OutageRequestFilterSchema = yup.object(OutageRequestFilterShape);

export const OutagesFormSchema = yup.object({
  facName: yup.string().required().default(''),
  updatedBy: yup.string().nullable(),
  createdBy: yup.string().nullable(),
});

export type IOutageRequestListItem = yup.Asserts<
  typeof OutageRequestListItemSchema
>;

export type IOutageRequestListPayloadItem = yup.Asserts<
  typeof OutageRequestListSchema
>;

export const OutagesDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});
export type IOutageRequestFilter = Partial<
  yup.Asserts<typeof OutageRequestFilterSchema>
>;

export type IOutageEmsData = yup.Asserts<typeof OutageEmsDataSchema>;
export type IOutageDate = yup.Asserts<typeof OutageDateSchema>;

export type IOutagesForm = yup.Asserts<typeof OutagesFormSchema>;
