import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ATMButton,
  ATMIcon,
  ATMLabel,
  IORGDataTableColumn,
  IORGDataTableQueryState,
  MOLNoData,
  ORGDataTable,
} from 'shared-it-appmod-ui';
import OutageStatus from 'src/components/atoms/outage-status/outage-status.component';
import { ToastWarning } from 'src/components/atoms/toaster/toaster.component';
import {
  LERNotificationStatus,
  LERRequestFormStep,
  LERRequestPage,
} from 'src/constants';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import Lang from 'src/libraries/language';
import { ILerRequestItem } from 'src/models/ler-request.model';
import {
  getLerRequestEndDate,
  getLerRequestStartDate,
  getLerRequestStatus,
} from 'src/selectors/ler-request.selector';
import { getEmployeeName } from 'src/helpers/employee.helper';
import OutageDay from 'src/components/atoms/outage-day/outage-day.component';
import { checkValue, getComponentText } from 'src/libraries/common.library';
import { buildExportData, convertToCSV } from 'src/selectors/file.selector';
import Moment from 'src/libraries/moment.library';
import { cleanSpecialCharacterData } from 'src/helpers/file-export.helper';
import OutagesListSearch from '../outage-list-search/outages-list-search.component';
import styles from '../../ler-request/ler-request.module.scss';

const OutagesList: React.FC = () => {
  const { state, actions } = useLerRequestContext();
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState<Partial<IORGDataTableQueryState>>();
  const status = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_OUTAGE_LIST_READ
  );
  const downloadStatus = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_OUTAGE_LIST_DOWNLOAD_READ
  );

  useEffect(() => {
    actions.setOutageList([]);

    return () => setQuery(undefined);
  }, [setQuery, actions]);

  const handleSubmit = useCallback(
    async (formData) => {
      if (query && !Object.keys(formData).includes('filters')) {
        const response = await actions.listOutageGET({
          ...formData,
          ...query,
          limit: 25,
          page: formData.page,
        });
        if (response.payload?.count === 0) {
          ToastWarning('No Results found for the search criteria');
        }
      }

      setRefresh(false);
    },
    [actions, query]
  );

  const {
    params: { lerView, lerEdit },
    getUrl,
  } = useLocationParams([LERRequestPage.View, LERRequestPage.Edit]);

  // This will refresh the list if the detail/edit page is closed
  useEffect(() => {
    if (refresh && !lerView && !lerEdit) {
      handleSubmit(query);
    }
  }, [refresh, lerView, lerEdit, query, setRefresh, handleSubmit]);

  useEffect(() => {
    if (lerView || lerEdit) {
      setRefresh(true);
    }
  }, [lerView, lerEdit, setRefresh]);

  const columns: IORGDataTableColumn<ILerRequestItem>[] = [
    {
      index: 'requestId',
      title: Lang.LBL_LER,
      render: (value) => (
        <Link to={getUrl({ [LERRequestPage.View]: value })}>{value}</Link>
      ),
    },
    {
      index: 'requestStatus',
      title: 'Request Status',
      render: (_, value) => <OutageStatus data={value} />,
    },
    {
      index: 'crStatus',
      title: 'CR Status',
      render: (_, value) =>
        value.changeReqStat && (
          <ATMLabel className={styles.labelTransparent}>
            {value.changeReqStat}
          </ATMLabel>
        ),
    },
    {
      index: `${LERRequestFormStep.OUTAGE_FACILITY}.facTypId`,
      title: Lang.LBL_OUTAGE_TYPE,
      render: (_, value) => value.outageFacility?.outageType?.outageTypeDesc,
    },
    {
      index: `${LERRequestFormStep.OUTAGE_FACILITY}.outageFacility.authorization`,
      title: 'Auth Type',
      render: (_, value) => (
        <span>{value.outageFacility.outgTypId ? 'OK' : 'NA'}</span>
      ),
      sortable: false,
    },
    {
      index: 'requestorName',
      title: Lang.LBL_REQUESTOR_NAME,
      render: (_, value) =>
        value.requestor ? getEmployeeName(value.requestor) : null,
    },
    {
      index: 'outageFacility',
      title: 'Facility Name',
      render: (_, value) => value.outageFacility?.facility?.outgFacNm,
    },
    {
      index: 'substation',
      title: 'Substation',
      render: (_, value) => value.outageFacility.substation?.name,
    },
    {
      index: 'startDate',
      title: 'Start Date',
      render: (_, value) => getLerRequestStartDate(value.outageDates),
    },
    {
      index: 'endDate',
      title: 'End Date',
      render: (_, value) => getLerRequestEndDate(value.outageDates),
    },
    {
      index: 'days',
      title: 'Days',
      render: (value, values) => <OutageDay day={value} data={values} />,
      sortable: false,
    },
    {
      index: 'emailGroups',
      title: 'Pending Email',
      render: (_, value) => {
        if (value.emailGroups && value.emailGroups.length > 0) {
          return value.emailGroups.some(
            (v) => v.status === LERNotificationStatus.PENDING
          ) ? (
            <ATMIcon name="exclamation circle" color="orange" />
          ) : (
            <ATMIcon name="check circle" color="green" />
          );
        }

        return null;
      },
      sortable: false,
    },
  ];

  const handleSearch = useCallback(
    (params) => {
      if (params) {
        const filters = Object.entries(params).reduce(
          (items: { name: string; value: any }[], [key, value]) => {
            if (value) {
              return [
                ...items,
                {
                  name: key,
                  value,
                },
              ];
            }
            return items;
          },
          []
        );

        setImmediate(() => {
          setQuery((values) => {
            const formData = !values
              ? { page: 1, limit: 25, filters }
              : { ...values, filters };
            handleSubmit(formData);
            return formData;
          });
        });
      } else {
        setQuery(undefined);
        actions.setOutageList([]);
      }
    },
    [setQuery, actions, handleSubmit]
  );

  const handleDownload = useCallback(async () => {
    const result = await actions.listOutageDownloadGET({
      ...query,
      limit: 0,
    });
    if (result.payload?.rows) {
      const list = result.payload.rows;
      const items = list.map((value) => [
        getComponentText(<OutageStatus data={value as any} />),
        checkValue(value.requestor ? getEmployeeName(value.requestor) : null),
        checkValue(value.crew ? getEmployeeName(value.crew) : null),
        checkValue(value.outageFacility?.facility?.facTyp?.facTypNm),
        checkValue(value.outageFacility?.voltage?.voltNm),
        checkValue(value.outageFacility?.facility?.outgFacNm),
        checkValue(value.outageFacility?.substation?.name),
        checkValue(value.recalTm),
        checkValue(getLerRequestStartDate(value.outageDates)),
        checkValue(getLerRequestEndDate(value.outageDates)),
        getComponentText(
          <OutageDay day={value.days || 0} data={value as any} />
        ),
        checkValue(value.outageFacility.outageType?.outageTypeDesc),
        `${checkValue(value.outgCategNm)} / ${checkValue(value.requestId)}`,
        checkValue(value?.rimsProjectId),
        checkValue(value?.rimsProjectPhase),
        checkValue(value?.wrkDesc),
        value.shortDescriptions.map((val) =>
          checkValue(val.category?.shortDescCat)
        ),
        value.shortDescriptions.map((val) =>
          checkValue(val.description?.shortDesc)
        ),
      ]);

      const { exportData, format } = convertToCSV(
        buildExportData(items, [
          Lang.LBL_OUTAGES_STATUS,
          Lang.LBL_OUTAGE_REQUESTOR_NAME,
          Lang.LBL_OUTAGES_CREW_NAME,
          Lang.LBL_OUTAGES_EQUIP_TYPE,
          Lang.LBL_OUTAGES_KV,
          Lang.LBL_OUTAGES_FACILITY_NAME,
          Lang.LBL_OUTAGES_SUBSTATION,
          Lang.LBL_OUTAGES_RECALL,
          Lang.LBL_START_DATE,
          Lang.LBL_OUTAGES_END_DATE,
          Lang.LBL_DAYS,
          Lang.LBL_OUTAGES_OUTAGE,
          Lang.LBL_OUTAGES_OUTAGE_CATEGORY,
          Lang.LBL_OUTAGES_RIMS_PROJECT_ID,
          Lang.LBL_OUTAGES_RIMS_PROJECT_PHASE,
          Lang.LBL_OUTAGES_WORK_DESCRIPTION,
          Lang.LBL_OUTAGES_SHORT_DESCRIPTION_CATEGORY,
          Lang.LBL_OUTAGES_SHORT_DESCRIPTION_DETAIL,
        ])
      );
      const link = document.createElement('a');
      link.setAttribute('href', cleanSpecialCharacterData(exportData));
      link.setAttribute(
        'download',
        `MCC_Outages_${Moment().format('YYYYMMDD')}.${format}`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [query]);

  return (
    <div>
      <OutagesListSearch
        handleSubmit={handleSearch}
        searchLoading={status.fetching}
      />
      <ORGDataTable
        columns={columns}
        rowsPerPageOptions={[25]}
        showPagination
        sortable
        data={state.outageList}
        onChange={handleSubmit}
        noDataText={
          <MOLNoData
            icon="search"
            header="Search to view records"
            subHeader="Please select or enter the search criteria and click on the search button to see the results."
          />
        }
        total={state.outageTotal ?? 0}
        counter
        loading={status.fetching}
        celled={false}
        toolbars={
          state.outageTotal > 0
            ? {
                download: () => (
                  <ATMButton
                    type="button"
                    secondary
                    icon="download"
                    size="tiny"
                    content={Lang.LBL_DOWNLOAD}
                    loading={downloadStatus.fetching}
                    disabled={downloadStatus.fetching}
                    onClick={handleDownload}
                  />
                ),
              }
            : undefined
        }
      />
    </div>
  );
};

export default OutagesList;
