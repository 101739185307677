import { httpClient } from 'src/libraries/http.library';
import { ContractorFirmListPayloadSchema } from 'src/models/contractor-firm.model';
import {
  IContractorForm,
  ContractorListPayloadSchema,
  ContractorDeletePayloadSchema,
  ContractorPayloadSchema,
} from 'src/models/contractor.model';

const client = httpClient();
const endpoint = 'contractor';
const endpointFirms = 'contractor-firm';

const services = {
  dataGET: async (id: number) => {
    return client.get(`/${endpoint}/${id}`, {}, ContractorPayloadSchema);
  },

  listGET: async (params?: any) => {
    return client.get(`/${endpoint}`, params, ContractorListPayloadSchema);
  },

  listWithActiveFirmGET: async (params?: any) => {
    return client.get(`/${endpoint}`, params, ContractorListPayloadSchema);
  },

  listFirmsGET: async () => {
    return client.get(`/${endpointFirms}`, {}, ContractorFirmListPayloadSchema);
  },

  createPOST: async (data: IContractorForm) => {
    return client.post(`/${endpoint}`, data, ContractorPayloadSchema);
  },

  updatePUT: async (id: string | undefined, data: IContractorForm) => {
    return client.put(`/${endpoint}/${id}`, data, ContractorPayloadSchema);
  },

  dataDELETE: async (id: string) => {
    return client.delete(`/${endpoint}/${id}`, ContractorDeletePayloadSchema);
  },
};

export default services;
