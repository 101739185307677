import yup from 'src/libraries/validator.library';

export const CancelTypeShape = {
  cancelTypeId: yup.number().required(),
  cancelTypeDesc: yup.string().required(),
  updatedAt: yup.date().required(),
  updatedBy: yup.string().required(),
  activeFlag: yup.boolean().required(),
};

export const CancelTypeSchema = yup.object(CancelTypeShape);

export const CancelTypeFormSchema = yup.object({
  cancelTypeDesc: yup.string().required().default(''),
});

export const CancelTypeListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(CancelTypeSchema.clone()),
});

export const CancelTypePayloadSchema = yup.object({
  cancelType: CancelTypeSchema.clone(),
});

export const CancelTypeDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type ICancelType = yup.Asserts<typeof CancelTypeSchema>;
export type ICancelTypeForm = yup.Asserts<typeof CancelTypeFormSchema>;
