import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import { IReason } from 'src/models/reason.model';
import services from 'src/services/reason.service';

export const reasonActionTypes = {
  REASON_LIST_READ: 'REASON_LIST_READ',
  REASON_LIST_REJECT_READ: 'REASON_LIST_REJECT_READ',
  REASON_LIST_CHANGE_READ: 'REASON_LIST_CHANGE_READ',
} as const;

export const duckActions = {
  listGET: {
    type: reasonActionTypes.REASON_LIST_READ,
    service: services.listGET,
  },

  listRejectGET: {
    type: reasonActionTypes.REASON_LIST_REJECT_READ,
    service: services.listRejectGET,
  },

  listChangeGET: {
    type: reasonActionTypes.REASON_LIST_CHANGE_READ,
    service: services.listChangeGET,
  },
};

export type IReasonAsync = typeof duckActions;

export interface IReasonState extends ICommonState<typeof reasonActionTypes> {
  data?: IReason;
  list: IReason[];
  listReject: IReason[];
  listChange: IReason[];
  total: number;
}

export const defaultState: IReasonState = {
  status: {},
  list: [],
  listReject: [],
  listChange: [],
  total: 0,
};

const ReasonReducer = (
  state: IReasonState,
  action: IReducerAction<IReasonAsync>
): IReasonState => {
  switch (action.type) {
    case reasonActionTypes.REASON_LIST_READ:
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    case reasonActionTypes.REASON_LIST_CHANGE_READ: {
      return {
        ...state,
        listChange: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }
    case reasonActionTypes.REASON_LIST_REJECT_READ: {
      return {
        ...state,
        listReject: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    default: {
      return state;
    }
  }
};

export default ReasonReducer;
