import { reasonActionTypes, IReasonState } from 'src/ducks/reason.duck';
import { IStatus } from 'src/libraries/thunk.library';

export const getReasonStatus = (
  state: IReasonState,
  action: keyof typeof reasonActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };
