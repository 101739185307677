import React, { useCallback, useRef, useState } from 'react';
import { useContractorFirmContext } from 'src/contexts/contractor-firm.context';
import { contractorFirmActionTypes } from 'src/ducks/contractor-firm.duck';
import { IContractorFirmForm } from 'src/models/contractor-firm.model';
import { getContractorFirmStatus } from 'src/selectors/contractor-firm.selector';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import ContractorFirmAddView from './contractor-firm-add.view';

const ContractorFirmAdd: React.FC = () => {
  const { state, actions } = useContractorFirmContext();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const status = getContractorFirmStatus(
    state,
    contractorFirmActionTypes.CONTRACTOR_FIRM_DATA_CREATE
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IContractorFirmForm) => {
      const response = await actions.createPOST({
        ...formData,
      });

      if (!response.error) {
        actions.listGET({
          limit: 0,
          page: 1,
          sort: 'contracShortNm',
          order: 'ascending',
        });
        setIsAddOpen(false);

        ToastSuccess(`Firm ${formData.contracShortNm} added successfully`);
      } else {
        const existingFirm = state.list.find(
          (value) => value.contracShortNm === formData.contracShortNm
        );
        if (existingFirm) {
          ToastError('Firm Short Name already exists');
        } else {
          ToastError('Error adding a new firm');
        }
      }
    },
    [actions, setIsAddOpen]
  );

  return (
    <ContractorFirmAddView
      formRef={formRef}
      loading={status.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      isOpen={isAddOpen}
      handleOpen={setIsAddOpen}
    />
  );
};

export default ContractorFirmAdd;
