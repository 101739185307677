import React, { useCallback, useState } from 'react';
import { useContractorFirmContext } from 'src/contexts/contractor-firm.context';
import { IContractorFirm } from 'src/models/contractor-firm.model';
import { getContractorFirmStatus } from 'src/selectors/contractor-firm.selector';
import { contractorFirmActionTypes } from 'src/ducks/contractor-firm.duck';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import ContractorFirmDeleteView from './contractor-firm-delete.view';

type IProp = {
  data: IContractorFirm;
};

const ContractorFirmDelete: React.FC<IProp> = ({ data }) => {
  const { state, actions } = useContractorFirmContext();
  const [isOpen, setIsOpen] = useState(false);

  const status = getContractorFirmStatus(
    state,
    contractorFirmActionTypes.CONTRACTOR_FIRM_DATA_DELETE
  );

  const handleSubmit = useCallback(async () => {
    if (data.contracShortNm) {
      const response = await actions.dataDELETE(data.contracShortNm);

      if (!response.error) {
        setIsOpen(false);
        actions.listGET({
          limit: 0,
          page: 1,
          sort: 'contracShortNm',
          order: 'ascending',
        });
        ToastSuccess(`Firm ${data.contracShortNm} deactivated successfully`);
      } else {
        setIsOpen(false);
        ToastError(response.error.message);
      }
    }
  }, [actions, data]);

  return (
    <ContractorFirmDeleteView
      data={data}
      isOpen={isOpen}
      loading={status.fetching}
      handleOpen={setIsOpen}
      handleSubmit={handleSubmit}
    />
  );
};

export default ContractorFirmDelete;
