import React, { useState } from 'react';
import {
  ORGDataTable,
  IORGDataTableColumn,
  IORGDataTableQueryState,
  ATMDropdown,
  ATMButton,
  MOLNoData,
  ATMGrid,
  ATMPopover,
} from 'shared-it-appmod-ui';
import useLocationTab from 'src/hooks/location-tab.hook';
import { IContractor } from 'src/models/contractor.model';
import { IContractorFirm } from 'src/models/contractor-firm.model';
import TableSearch from 'src/components/atoms/table/table-search/table-search.component';
import Lang from 'src/libraries/language';
import history from 'src/history';
import { getEmployeeName } from 'src/helpers/employee.helper';
import ListStatus from 'src/components/atoms/list-status/list-status.component';
import ContractorEdit from '../contractor-edit/contractor-edit.component';
import ContractorDelete from '../contractor-delete/contractor-delete.component';
import AuthListDetails from '../../authorized-list/authorized-list-details/auth-list-details.component';
import style from './contractor-list.module.scss';

type IProps = {
  data: IContractor[];
  firms: IContractorFirm[];
  loading: boolean;
  noData: string;
  loadingFirms: boolean;
  handleFetch: (params: IORGDataTableQueryState) => void;
  handleDownload: (params: IORGDataTableQueryState) => void;
};

const ContractorListView: React.FC<IProps> = ({
  data,
  noData,
  loading,
  loadingFirms,
  handleFetch,
  firms,
  handleDownload,
}) => {
  const { currentTab, handleTabChange } = useLocationTab('id');
  const [contractorList, setContractorList] = useState<IContractor>();

  const columns: IORGDataTableColumn<IContractor>[] = [
    {
      index: 'fullName',
      title: 'Name',
      width: '25%',
      render: (_, value, __, { isExpand }) => {
        return (
          <>
            {value.fullName &&
            ['A', 'D', 'G', 'J', 'M', 'P', 'S', 'V', 'Y'].includes(
              value.fullName.charAt(0)
            ) ? (
              // value.fullName.charAt(0) ===
              //   ('A' || 'D' || 'G' || 'J' || 'M' || 'P' || 'S' || 'V' || 'Y')
              <>
                <span
                  className={`${style.capitalLetterA} ${style.capitalLetterStyled}`}
                >
                  {value.fullName.charAt(0)}
                </span>{' '}
                <span
                  className={
                    isExpand ? style.nameEmployeeExpanded : style.nameEmployee
                  }
                >
                  <a
                    role="button"
                    style={{ color: 'inherit' }}
                    onClick={() => {
                      handleTabChange(`${value.empId}&tab=0`);
                      setContractorList(value);
                    }}
                  >
                    {getEmployeeName(value)}
                  </a>
                </span>
                {isExpand ? (
                  <ATMGrid
                    className={`${style.expandContainer} ${style.borderLeft} ${style.borderA}`}
                  >
                    <p>
                      <span>{Lang.LBL_CONTRACTOR_FIRM_SHORT_NAME}:</span>{' '}
                      {value.contracShortNm ?? '--'}
                    </p>
                    <p>
                      <a
                        role="button"
                        onClick={() => {
                          handleTabChange(`${value.empId}&tab=4`);
                          setContractorList(value);
                        }}
                      >
                        {Lang.LBL_MORE_DETAILS}
                      </a>
                    </p>
                  </ATMGrid>
                ) : (
                  ''
                )}
              </>
            ) : (
              <>
                {value.fullName &&
                ['B', 'E', 'H', 'K', 'N', 'Q', 'T', 'W', 'X'].includes(
                  value.fullName.charAt(0)
                ) ? (
                  // value.fullName.charAt(0) ===
                  //   ('B' ||
                  //     'E' ||
                  //     'H' ||
                  //     'K' ||
                  //     'N' ||
                  //     'Q' ||
                  //     'T' ||
                  //     'W' ||
                  //     'X')
                  <>
                    <span
                      className={`${style.capitalLetterB} ${style.capitalLetterStyled}`}
                    >
                      {value.fullName.charAt(0)}
                    </span>{' '}
                    <span
                      className={
                        isExpand
                          ? style.nameEmployeeExpanded
                          : style.nameEmployee
                      }
                    >
                      <a
                        role="button"
                        style={{ color: 'inherit' }}
                        onClick={() => {
                          handleTabChange(`${value.empId}&tab=4`);
                          setContractorList(value);
                        }}
                      >
                        {getEmployeeName(value)}
                      </a>
                    </span>
                    {isExpand ? (
                      <ATMGrid
                        className={`${style.expandContainer} ${style.borderLeft} ${style.borderB}`}
                      >
                        <p>
                          <span>{Lang.LBL_CONTRACTOR_FIRM_SHORT_NAME}:</span>{' '}
                          {value.contracShortNm ?? '--'}
                        </p>
                        <p>
                          <a
                            role="button"
                            onClick={() => {
                              handleTabChange(`${value.empId}&tab=4`);
                              setContractorList(value);
                            }}
                          >
                            {Lang.LBL_MORE_DETAILS}
                          </a>
                        </p>
                      </ATMGrid>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <>
                    <span
                      className={`${style.capitalLetterC} ${style.capitalLetterStyled}`}
                    >
                      {value.fullName && value.fullName.charAt(0)}
                    </span>{' '}
                    <span
                      className={
                        isExpand
                          ? style.nameEmployeeExpanded
                          : style.nameEmployee
                      }
                    >
                      <a
                        role="button"
                        style={{ color: 'inherit' }}
                        onClick={() => {
                          handleTabChange(`${value.empId}&tab=4`);
                          setContractorList(value);
                        }}
                      >
                        {getEmployeeName(value)}
                      </a>
                    </span>
                    {isExpand ? (
                      <ATMGrid
                        className={`${style.expandContainer} ${style.borderLeft} ${style.borderC}`}
                      >
                        <p>
                          <span>{Lang.LBL_CONTRACTOR_FIRM_SHORT_NAME}:</span>{' '}
                          {value.contracShortNm ?? '--'}
                        </p>
                        <p>
                          <a
                            role="button"
                            onClick={() => {
                              handleTabChange(`${value.empId}&tab=4`);
                              setContractorList(value);
                            }}
                          >
                            {Lang.LBL_MORE_DETAILS}
                          </a>
                        </p>
                      </ATMGrid>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </>
            )}
          </>
        );
      },
    },
    {
      index: 'contractorFirm.fullName',
      title: 'Firm Name',
    },
    {
      index: 'email',
      title: 'Email',
    },
    {
      index: 'employeeStat',
      title: 'Status',
      render: (_, value) => (
        <>
          <ListStatus value={value?.employeeStat} />
        </>
      ),
    },
    {
      index: 'contractorFirm.activeFlag',
      title: 'Firm Status',
      sortable: false,
      render: (_, value) => (
        <>{value.contractorFirm?.activeFlag == false ? 'Inactive' : 'Active'}</>
      ),
    },
    {
      index: 'action1',
      title: '',
      sortable: false,
      width: '50px',
      headerProps: {
        textAlign: 'center',
      },
      cellProps: {
        textAlign: 'center',
      },
      render: (_, _value, __, { isExpand, setIsExpand }) => (
        <div style={{ display: 'flex' }}>
          {isExpand ? (
            <>
              <div style={{ height: '10em', display: 'block' }}>
                <ATMButton
                  icon="angle up"
                  onClick={() => setIsExpand(!isExpand)}
                />
              </div>
            </>
          ) : (
            <>
              <ATMButton
                icon="angle down"
                onClick={() => setIsExpand(!isExpand)}
              />
            </>
          )}
        </div>
      ),
    },
    {
      index: 'action',
      title: '',
      width: '2%',
      sortable: false,
      cellProps: { textAlign: 'right' },
      render: (_, value) => {
        return (
          <div
            style={{ display: 'flex', margin: '0' }}
            className={style.dropdownButtons}
          >
            <ATMDropdown
              pointing
              size="large"
              button
              icon="ellipsis horizontal"
            >
              <ATMDropdown.Menu>
                <ContractorEdit data={value} />
                <ContractorDelete data={value} />
                {value.trainings && value.trainings.length > 0 ? (
                  <ATMDropdown.Item
                    icon="eye"
                    compact
                    content="View Training"
                    onClick={() => {
                      handleTabChange(
                        `${value.empId}&tab=4&getPreviousSearch=true`
                      );
                    }}
                  />
                ) : value.contractorFirm?.activeFlag === false ? (
                  <ATMPopover
                    key={`popup_${value.empId}`}
                    position="bottom left"
                    content="Unable to add a training. Contractor's firm is inactive."
                    on="hover"
                    trigger={
                      <ATMDropdown.Item
                        icon="plus"
                        compact
                        content="Add Training"
                      />
                    }
                  />
                ) : (
                  <ATMDropdown.Item
                    icon="plus"
                    compact
                    content="Add Training"
                    onClick={() => {
                      history.push(`/auth-list?id=${value.empId}&tab=0`);
                    }}
                  />
                )}
              </ATMDropdown.Menu>
            </ATMDropdown>
          </div>
        );
      },
    },
  ];

  if (currentTab) {
    return (
      <>
        <AuthListDetails
          data={contractorList as any}
          handleTabChange={handleTabChange}
          titleMainBreadcrumb="Contractors"
          breadcrumbPath="/auth-list?tab=4&getPreviousSearch=true"
        />
      </>
    );
  }

  return (
    <>
      <div className="admin-content">
        <ORGDataTable
          columns={columns}
          data={data}
          onChange={handleFetch}
          loading={loading}
          counter
          sortable
          celled={false}
          location={history.location}
          handleLocation={history.push}
          rowsPerPageOptions={[25, 50, 100]}
          defaultRowsPerPage={25}
          noDataText={
            <MOLNoData
              icon="search"
              header={noData}
              subHeader="Please select or enter the search criteria and click on the search button to see the results."
            />
          }
        >
          {({ state, setState }) => ({
            toolbars: {
              search: () => (
                <TableSearch
                  field="fullName"
                  state={state}
                  setState={setState}
                  placeholder={Lang.LBL_SEARCH_NAME}
                />
              ),
              contracShortNm: ({ value, setValue }) => (
                <ATMDropdown
                  name="firmName"
                  placeholder="Firm Name"
                  selection
                  clearable
                  value={value}
                  loading={loadingFirms}
                  options={firms
                    // .filter((value) => value.activeFlag)
                    .map((valueFirm) => {
                      return {
                        key: valueFirm.contracShortNm,
                        value: valueFirm.contracShortNm,
                        text: valueFirm.contracShortNm,
                      };
                    })}
                  onChange={(_, val) => {
                    setValue(val.value);
                    return val.value;
                  }}
                  selectOnBlur={false}
                  search
                />
              ),
              buttons: () => (
                <ATMButton
                  key="download"
                  secondary
                  size="tiny"
                  icon="download"
                  onClick={() => {
                    handleDownload({
                      ...state,
                      page: 1,
                      limit: 0,
                    });
                  }}
                />
              ),
            },
          })}
        </ORGDataTable>
      </div>
    </>
  );
};

export default ContractorListView;
