import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  ATMButton,
  ATMField,
  ATMForm,
  ATMModal,
  ATMSelect,
  ATMTextArea,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import {
  ILerRequest,
  ILerRequestCancelForm,
  LerRequestCancelFormSchema,
} from 'src/models/ler-request.model';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { getLerRequestStatus } from 'src/selectors/ler-request.selector';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import AccessComponent, {
  AccessType,
} from 'src/components/atoms/access/access.component';
import {
  hasChangeRequest,
  isLerRequestBeyond30Days,
  isRequestNewAndCreatedBySPVorOPE,
} from 'src/helpers/ler-request.helper';
import { useCancelTypeContext } from 'src/contexts/cancel-type.context';
import { getCancelTypeStatus } from 'src/selectors/cancel-type.selector';
import { cancelTypeActionTypes } from 'src/ducks/cancel-type.duck';
import { LERRequestStatus } from 'src/constants';

type IProp = {
  data: ILerRequest;
  trigger: React.ReactNode;
  handleSuccess?: () => Promise<void> | void;
};

const LERRequestCancel: React.FC<IProp> = ({
  data,
  trigger,
  handleSuccess,
}) => {
  const { state, actions } = useLerRequestContext();
  const { state: cancelTypeState, actions: cancelTypeActions } =
    useCancelTypeContext();
  const formRef = useRef<HTMLFormElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const status = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_CANCEL
  );

  const listStatus = getCancelTypeStatus(
    cancelTypeState,
    cancelTypeActionTypes.CANCEL_TYPE_LIST_READ
  );

  useEffect(() => {
    if (isOpen) {
      cancelTypeActions.listGET();
    }
  }, [isOpen, cancelTypeActions]);

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: ILerRequestCancelForm) => {
      if (data.requestId) {
        const response = await actions?.cancelPUT(data.requestId, formData);

        if (response.payload) {
          setIsOpen(false);

          ToastSuccess(
            hasChangeRequest(response.payload)
              ? Lang.MSG_LER_REQUEST_CANCEL_CHANGE_SUCCESS
              : Lang.MSG_LER_REQUEST_CANCEL_SUCCESS
          );
          if (response.payload?.outageEmail) {
            if (response.payload?.outageEmail?.successOutageEmail?.length) {
              response.payload?.outageEmail?.successOutageEmail.map(
                (emailOutage) => {
                  ToastSuccess(
                    emailOutage?.message ?? 'Email sent successfully'
                  );
                }
              );
            }
            if (response.payload?.outageEmail?.failedOutageEmail?.length) {
              response.payload?.outageEmail?.failedOutageEmail.map(
                (emailOutage) => {
                  ToastError(emailOutage?.message ?? 'Failed to send email');
                }
              );
            }
          }

          if (handleSuccess) {
            handleSuccess();
          }
        }
      }
    },
    [actions, data, handleSuccess]
  );

  const isAllow = () => {
    let allow = false;

    if (
      isLerRequestBeyond30Days(data.outageDates) === false &&
      [
        LERRequestStatus.Approved,
        LERRequestStatus.Submitted,
        LERRequestStatus.Scheduled,
      ].includes(data.requestStat as LERRequestStatus) &&
      hasChangeRequest(data) === false
    ) {
      allow = true;
    }

    // Don't allow cancel if it's new LER and created by SPV and OPE
    if (isRequestNewAndCreatedBySPVorOPE(data)) {
      allow = false;
    }

    return allow;
  };

  return (
    <AccessComponent
      type={[AccessType.OUTAGE_REQUEST_CANCEL, AccessType.OUTAGE_CANCEL]}
      when={isAllow()}
    >
      <ATMModal
        open={isOpen}
        size="tiny"
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        trigger={trigger}
      >
        <ATMModal.Header>{Lang.TTL_LER_REQUEST_CANCEL}</ATMModal.Header>
        <ATMModal.Content>
          <ATMForm
            ref={formRef}
            onSubmit={handleSubmit}
            mode="onChange"
            validationSchema={LerRequestCancelFormSchema}
          >
            {({ control, formState: { errors } }) => (
              <>
                <ATMField
                  name="cancelTypeId"
                  label={Lang.LBL_CANCELLED_BY_ROLE}
                  control={control}
                  as={ATMSelect}
                  options={cancelTypeState.list.map((value) => ({
                    key: value.cancelTypeId,
                    value: value.cancelTypeId,
                    text: value.cancelTypeDesc,
                  }))}
                  onChange={([_, { value }]) => value}
                  selectOnBlur={false}
                  clearable
                  error={errors.cancelTypeId}
                  loading={
                    cancelTypeState.list.length === 0 && listStatus.fetching
                  }
                  disabled={
                    cancelTypeState.list.length === 0 && listStatus.fetching
                  }
                  search
                />
                <ATMField
                  name="cancelNote"
                  label={Lang.LBL_CANCEL_REASON}
                  control={control}
                  as={ATMTextArea}
                  error={errors.cancelNote}
                  charLimit={1000}
                  charCount
                />
              </>
            )}
          </ATMForm>
        </ATMModal.Content>
        <ATMModal.Actions>
          <ATMButton
            secondary
            content={Lang.LBL_CANCEL}
            onClick={() => setIsOpen(false)}
            type="button"
          />
          <ATMButton
            primary
            content={Lang.LBL_CONFIRM}
            onClick={handleClick}
            disabled={status.fetching}
            loading={status.fetching}
            type="button"
          />
        </ATMModal.Actions>
      </ATMModal>
    </AccessComponent>
  );
};

export default LERRequestCancel;
