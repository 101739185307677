import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/contractor.service';
import { IContractor } from 'src/models/contractor.model';
import { IContractorFirm } from 'src/models/contractor-firm.model';

export const contractorActionTypes = {
  CONTRACTOR_DATA_READ: 'CONTRACTOR_DATA_READ',
  CONTRACTOR_LIST_READ: 'CONTRACTOR_LIST_READ',
  CONTRACTOR_LIST_ACTIVE_FIRM_READ: 'CONTRACTOR_LIST_ACTIVE_FIRM_READ',
  CONTRACTOR_FIRMS_LIST_READ: 'CONTRACTOR_FIRMS_LIST_READ',
  CONTRACTOR_DATA_CREATE: 'CONTRACTOR_DATA_CREATE',
  CONTRACTOR_DATA_UPDATE: 'CONTRACTOR_DATA_UPDATE',
  CONTRACTOR_DATA_DELETE: 'CONTRACTOR_DATA_DELETE',
  CONTRACTOR_DATA_SET: 'CONTRACTOR_DATA_SET',
  CONTRACTOR_CLEAR_LIST: 'CONTRACTOR_CLEAR_LIST',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: contractorActionTypes.CONTRACTOR_DATA_READ,
    service: services.dataGET,
  },

  listGET: {
    type: contractorActionTypes.CONTRACTOR_LIST_READ,
    service: services.listGET,
  },

  listWithActiveFirmGET: {
    type: contractorActionTypes.CONTRACTOR_LIST_ACTIVE_FIRM_READ,
    service: services.listWithActiveFirmGET,
  },

  listFirmsGET: {
    type: contractorActionTypes.CONTRACTOR_FIRMS_LIST_READ,
    service: services.listFirmsGET,
  },

  createPOST: {
    type: contractorActionTypes.CONTRACTOR_DATA_CREATE,
    service: services.createPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  updatePUT: {
    type: contractorActionTypes.CONTRACTOR_DATA_UPDATE,
    service: services.updatePUT,
  },

  dataDELETE: {
    type: contractorActionTypes.CONTRACTOR_DATA_DELETE,
    service: services.dataDELETE,
  },

  clearList: () => ({
    type: contractorActionTypes.CONTRACTOR_CLEAR_LIST,
  }),

  // This is a sync action
  setData: (contractor: IContractor) => ({
    type: contractorActionTypes.CONTRACTOR_DATA_SET,
    payload: contractor,
  }),
};

export type IContractorAsync = typeof duckActions;

export interface IContractorState
  extends ICommonState<typeof contractorActionTypes> {
  data?: IContractor;
  list: IContractor[];
  firmslist: IContractorFirm[];
  total: number;
}

export const defaultState: IContractorState = {
  status: {},
  list: [],
  firmslist: [],
  total: 0,
};

const ContractorReducer = (
  state: IContractorState,
  action: IReducerAction<IContractorAsync>
): IContractorState => {
  switch (action.type) {
    case contractorActionTypes.CONTRACTOR_DATA_SET:
    case contractorActionTypes.CONTRACTOR_DATA_READ:
    case contractorActionTypes.CONTRACTOR_DATA_UPDATE:
    case contractorActionTypes.CONTRACTOR_DATA_CREATE: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case contractorActionTypes.CONTRACTOR_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }
    case contractorActionTypes.CONTRACTOR_LIST_ACTIVE_FIRM_READ: {
      const withActiveFirms = action.payload?.rows?.filter(
        (value) =>
          value.contractorFirm?.activeFlag ||
          value.contractorFirm?.activeFlag === null
      );

      return {
        ...state,
        list: withActiveFirms ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    case contractorActionTypes.CONTRACTOR_FIRMS_LIST_READ: {
      return {
        ...state,
        firmslist: action.payload?.rows ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    case contractorActionTypes.CONTRACTOR_DATA_DELETE: {
      if (action.params) {
        const [id] = action.params;
        const list = state.list.filter((value) => value.empId !== id);

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }

    case contractorActionTypes.CONTRACTOR_CLEAR_LIST: {
      return {
        ...state,
        list: [],
      };
    }

    default: {
      return state;
    }
  }
};

export default ContractorReducer;
