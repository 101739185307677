import React from 'react';
import { LERRequestFormStep } from 'src/constants';
import {
  ILerRequest,
  ILerRequestForm,
  ILerRequestOutageDate,
  ILerRequestOutageFacility,
  ILerRequestOutageFacilityListForm,
} from 'src/models/ler-request.model';
import LERRequestOutageDate from '../ler-request-outage-date/ler-request-outage-date.component';
import LERRequestOutageFacility from '../ler-request-outage-facility/ler-request-outage-facility.component';
import { FieldArrayWithId } from 'react-hook-form';

type IProps = {
  data: Partial<ILerRequest | ILerRequestForm>;
  handleEdit?: (step: LERRequestFormStep) => void;
  handleDelete?: (key: string | string[]) => void;
  setClose?: () => void;
  fields?: FieldArrayWithId<ILerRequestOutageDate>[];
  handleAdd?: (
    list: ILerRequestOutageFacilityListForm,
    callback: () => void
  ) => void;
  facilityBesInd?: string;
};

const LERRequestPanel: React.FC<IProps> = ({
  data,
  facilityBesInd,
  fields,
  handleAdd,
  handleEdit,
  handleDelete,
}) => {
  return (
    <>
      <LERRequestOutageFacility
        data={data.outageFacility as ILerRequestOutageFacility}
        handleEdit={
          handleEdit
            ? () => handleEdit(LERRequestFormStep.OUTAGE_FACILITY)
            : undefined
        }
      />
      <LERRequestOutageDate
        data={data[LERRequestFormStep.OUTAGE_DATE] as ILerRequestOutageDate[]}
        handleDelete={handleDelete}
        handleEdit={
          handleEdit
            ? () => handleEdit(LERRequestFormStep.OUTAGE_DATE)
            : undefined
        }
        facilityBesInd={facilityBesInd}
        fields={fields}
        handleAdd={handleAdd}
      />
    </>
  );
};

export default LERRequestPanel;
