import React from 'react';
import {
  ATMGrid,
  ATMHeader,
  ORGDataTable,
  IORGDataTableColumn,
  ATMDropdown,
  ATMButton,
  ATMPopover,
} from 'shared-it-appmod-ui';
import { AccessRole, AuthListType } from 'src/constants';
import history from 'src/history';
import { orderBy } from 'lodash';
import { getIsAdmin } from 'src/libraries/access.library';
import Lang from 'src/libraries/language';
import { formatDate } from 'src/libraries/moment.library';
import {
  IAuthorizedList,
  IAuthorizedListTraining,
} from 'src/models/authorized-list.model';
import { ISubCategory } from 'src/models/sub-category.model';
import AuthorizedListDelete from '../authorized-list-delete/authorized-list-delete.component';
import AuthorizedListEdit from '../authorized-list-edit/authorized-list-edit.component';
import style from '../authorized-list.module.scss';

type IProp = {
  data?: IAuthorizedList;
  loading: boolean;
  subCategoryList: ISubCategory[];
  handleDownload: () => void;
  subCategoryStatus: boolean;
  handleFetch: () => void;
};

const TrainingHistoryListView: React.FC<IProp> = ({
  data,
  loading,
  handleDownload,
  subCategoryList,
  subCategoryStatus,
  handleFetch,
}) => {
  const columns: IORGDataTableColumn<IAuthorizedListTraining>[] = [
    {
      index: 'authTypId',
      title: 'Auth Type',
    },
    {
      index: 'trainingDate',
      title: 'Training Date',
      render: (_, value) => formatDate(value.trainingDate),
    },
    {
      index: 'expiryDate',
      title: 'Training Expiration Date',
      render: (_, value) =>
        value.expiryDate?.includes('9999')
          ? '--'
          : formatDate(value.expiryDate),
    },
    {
      index: 'contracShortNm',
      title: 'Firm Name',
    },
    {
      index: 'subcategory',
      title: 'Sub Category',
      render: (_, value) => value.subcategory?.description,
    },
    {
      index: 'trainerName',
      title: 'Trainer',
    },
    {
      index: 'testFailInd',
      title: 'Failed Test',
      render: (_, value) => (!value.testFailInd ? 'No' : 'Yes'),
    },
    {
      index: 'action',
      sortable: false,
      title: Lang.LBL_ACTION,
      headerProps: {
        textAlign: 'center',
      },
      cellProps: {
        textAlign: 'center',
      },
      render: (_, value) =>
        getIsAdmin(AccessRole.GCC_AUTH_LIST_ADMIN) && (
          <>
            <ATMDropdown size="small" key="" icon="ellipsis horizontal">
              <ATMDropdown.Menu>
                {data?.contractorFirm?.activeFlag === false &&
                [AuthListType.Training, AuthListType.SubEntry].includes(
                  value.authTypId as AuthListType
                ) ? (
                  <ATMPopover
                    key={`popup_${value.authListId}`}
                    position="bottom left"
                    content="Contractor's firm is inactive."
                    on="hover"
                    trigger={<ATMDropdown.Item content="Edit" icon="edit" />}
                  />
                ) : (
                  <AuthorizedListEdit
                    trainingHistory
                    subCategoryList={subCategoryList}
                    data={data}
                    entryText={value.authTypId}
                    trainingData={value}
                    subCategoryStatus={subCategoryStatus}
                  />
                )}
                <AuthorizedListDelete
                  id={value.authListId}
                  empName={data?.fullName}
                  empId={data?.empId}
                />
              </ATMDropdown.Menu>
            </ATMDropdown>
          </>
        ),
    },
  ];
  return (
    <>
      <ATMGrid>
        <ATMGrid.Column verticalAlign="middle">
          <div className={style.flexTitleButton}>
            <ATMHeader as="h3" style={{ margin: '0.5em 0 1em' }}>
              Training History
            </ATMHeader>
            <ATMButton
              content="Download"
              icon="download"
              secondary
              type="button"
              onClick={() => handleDownload()}
            />
          </div>
        </ATMGrid.Column>
      </ATMGrid>

      <div className="admin-content">
        <ORGDataTable
          columns={columns}
          data={
            orderBy(
              data?.trainings,
              ['authTypId', 'trainingDate'],
              ['asc', 'desc']
            ) ?? []
          }
          onChange={handleFetch}
          loading={loading}
          location={history.location}
          handleLocation={history.push}
          celled={false}
          style={{ marginRight: '10px' }}
          sortable
        />
      </div>
    </>
  );
};

export default TrainingHistoryListView;
