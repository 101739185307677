import React, { useEffect, useState } from 'react';
import {
  ATMButton,
  ATMField,
  ATMInput,
  ATMModal,
  ATMSelect,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { ILerRequestForm } from 'src/models/ler-request.model';
import { useReasonContext } from 'src/contexts/reason.context';
import { getReasonStatus } from 'src/selectors/reason.selector';
import { reasonActionTypes } from 'src/ducks/reason.duck';

type IProps = {
  loading: boolean;
  disabled: boolean;
  handleUpdate: () => void;
};

const LerRequestUpdate: React.FC<IProps> = ({
  handleUpdate,
  loading,
  disabled,
}) => {
  const {
    control,
    formState: { errors },
  } = useATMFormContext<ILerRequestForm>();
  const [isOpen, setIsOpen] = useState(false);
  const [reason, setReason] = useState(false);
  const { state: reasonState, actions: reasonActions } = useReasonContext();

  const listStatus = getReasonStatus(
    reasonState,
    reasonActionTypes.REASON_LIST_CHANGE_READ
  );

  useEffect(() => {
    if (
      isOpen &&
      (!reasonState.listChange || reasonState.listChange.length === 0)
    ) {
      reasonActions.listChangeGET();
    }
  }, [isOpen]);

  return (
    <>
      <ATMModal
        open={isOpen}
        size="tiny"
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        closeOnDimmerClick={false}
        trigger={
          <ATMButton
            primary
            content={Lang.LBL_UPDATE}
            type="button"
            loading={loading}
            disabled={disabled}
          />
        }
      >
        <ATMModal.Header>Submit LER Change Request</ATMModal.Header>
        <ATMModal.Content>
          <div>
            <p>{Lang.MSG_CHANGE_LER}</p>

            <ATMField
              as={ATMInput}
              type="hidden"
              className="hidden"
              name="willCauseChangeRequest"
              control={control}
            />
            <ATMField
              name="changeRequestReasonId"
              label={Lang.LBL_CHANGE_REQUEST_REASON}
              placeholder="Select"
              control={control}
              as={ATMSelect}
              options={reasonState.listChange.map((value) => ({
                key: value.reasonId,
                value: value.reasonId,
                text: value.reasonDesc,
              }))}
              onChange={([_, { value }]) => {
                if (value) {
                  setReason(true);
                }
                return value;
              }}
              selectOnBlur={false}
              // clearable
              error={errors.changeRequestReasonId}
              loading={
                reasonState.listChange.length === 0 && listStatus.fetching
              }
              disabled={
                reasonState.listChange.length === 0 && listStatus.fetching
              }
              search
            />
          </div>
        </ATMModal.Content>
        <ATMModal.Actions>
          <ATMButton
            secondary
            content={Lang.LBL_CANCEL}
            onClick={() => setIsOpen(false)}
            type="button"
          />
          <ATMButton
            type="button"
            primary
            content={Lang.LBL_CONFIRM}
            onClick={() => {
              setIsOpen(false);
              handleUpdate();
            }}
            disabled={!reason}
            loading={loading}
          />
        </ATMModal.Actions>
      </ATMModal>
    </>
  );
};

export default LerRequestUpdate;
