import { httpClient } from 'src/libraries/http.library';
import { ReasonListPayloadSchema } from 'src/models/reason.model';

const client = httpClient();
const endpoint = 'reason';

const services = {
  listGET: async () => {
    return client.get(`/${endpoint}`, {}, ReasonListPayloadSchema);
  },

  listRejectGET: async () => {
    return client.get(
      `/${endpoint}?action=reject`,
      {},
      ReasonListPayloadSchema
    );
  },

  listChangeGET: async () => {
    return client.get(
      `/${endpoint}?action=change`,
      {},
      ReasonListPayloadSchema
    );
  },
};

export default services;
