export default {
  MSG_ERROR_REQUIRED: 'This field is required',
  MSG_ERROR_COORDINATES: 'Invalid coordinates',
  MSG_ERROR_CONTACT_NUMBER: 'Please enter a valid phone number',
  MSG_HTTP_ERROR_BAD_REQUEST: 'Request failed with status code {0}',
  MSG_HTTP_ERROR_REQUEST_CANCELLED: 'The request was cancelled',
  MSG_NOTIFICATION_BLOCK_EMPTY: 'No items to display',
  MSG_EMPTY_FILTER: 'No records/data found for the selected criteria',
  MSG_FILE_CONFIRM_DELETE: 'Are you sure you want to delete this file?',
  MSG_CONFIRM_DELETE: 'Are you sure you want to delete this file?',
  MSG_CONFIRM_DELETE_NOTE: 'Are you sure you want to delete this note?',

  MSG_OUTAGE_DATE_ERROR: 'There are overlaps on your selected outage dates',
  MSG_USER_GROUP_EMAIL_ERROR:
    'Field is required when there is no group members',
  MSG_USER_GROUP_MEMBER_ERROR: 'Field is required when there is no group email',
  MSG_USER_GROUP_MEMBER_EXISTS: 'Member already part of the group',
  MSG_USER_GROUP_ONE_MEMBER_ERROR:
    'Cannot delete - group member or group email is required',

  MSG_NUMBER_OF_KEYS_MIN_MAX: 'The Number of keys must be between 1 and 50',
  MSG_KEY_NUMBER_ERROR_30_KEY:
    'Key Number must begin with 30 or 31 for 30 Key. Example: 30119 or 31119.',
  MSG_KEY_NUMBER_ERROR_40_KEY:
    'Key Number must begin with 40 or 41 for 40 Key. Example: 40119 or 41119.',
  MSG_KEY_NUMBER_ERROR_LENGTH: 'Key Number has to be 5 digits',
  MSG_KEY_NUMBER_NUMERIC: 'Key Number has to be Numeric',
  MSG_NO_OF_KEYS_NUMERIC: 'No. of Keys has to be Numeric',

  MSG_VALID_KEY_NUMBERS: 'Valid Key Number(s)',
  MSG_INVALID_KEY_NUMBERS: 'Invalid Starting Key Number',
  MSG_SUCCESS_ADD_SUBSTATION: 'Substation {0} has been created successfully',
  MSG_SUCCESS_DELETE_SUBSTATION: 'Substation {0} has been deleted',
  MSG_SUCCESS_EDIT_SUBSTATION: 'Substation {0} has been updated successfully',

  MSG_FACILITY_NAME_ERROR_LENGTH: 'Facility Name has to be 5 characters',
  MSG_USER_GROUP_ADD: 'Group {0} added successfully',
  MSG_USER_GROUP_EDIT: 'Group {0} updated successfully',
  MSG_USER_GROUP_DELETE: 'Group {0} removed successfully',
  MSG_CONFIRM_USER_GROUP_DELETE: 'Are you sure you want to remove {0}?',

  MSG_REAL_TIME_LOG_ADD: 'Log Entry has been added',
  MSG_REAL_TIME_LOG_EDIT: 'Log Entry has been updated successfully',
  MSG_REAL_TIME_LOG_NOTE_EDIT: 'Log Note Entry has been updated successfully',

  MSG_WORK_GROUP_EDIT: 'Workgroup {0} has been updated successfully',
  MSG_WORK_GROUP_ADD: 'Workgroup {0} has been added successfully',
  MSG_WORK_GROUP_DELETE: 'Workgroup {0} has been deleted successfully',
  MSG_WORK_GROUP_NAME_ERROR: 'Work Group Name is required',
  MSG_WORK_GROUP_EMAIL_ERROR: 'Work group email is required',
  MSG_WORK_GROUP_EMAIL_NOT_VALID: 'Work group email is not valid',
  MSG_WORK_GROUP_EMAIL_ERROR_EDIT: 'At least one work group email is required',
  MSG_WORK_GROUP_EMAIL_DUPLICATE: "Error - Can't have duplicate mailing list",
  MSG_WORK_GROUP_SUB_GRP_DUPLICATE: "Error - Can't have duplicate sub group",
  MSG_WORK_GROUP_SUB_GRP_CANNOT_DELETE:
    "Error - Can't delete sub group used in trouble ticket",

  MSG_CONTRACTOR_ADD: 'Contractor {0} added successfully',
  MSG_CONTRACTOR_EDIT: 'Contractor {0} updated successfully',
  MSG_CONTRACTOR_DELETE: 'Contractor {0} deleted successfully',

  MSG_LER_REQUEST_SUBMIT_APPROVE:
    'Are you sure you want to submit and approve the LER?',
  MSG_TROUBLE_JOB_ADD:
    'Trouble Job Has Been Created. You May Add Switching Request',
  MSG_TROUBLE_JOB_EDIT:
    'Trouble job  has been updated successfully and switching request(s) have been updated successfully',
  MSG_TROUBLE_JOB_STATION_ALARM: 'Station Alarm Job Has Been Created',
  MSG_TROUBLE_JOB_END_DATE_REQUIRED:
    'End Date and End Time are Required Fields',
  MSG_TROUBLE_JOB_END_DATE_ERROR:
    'End Date/Time must be later than Begin Date/Time',

  MSG_CHECK_OUT_SUCCESS: 'Successfully checked out',
  MSG_NO_ETS_DISPATCH_CREATED: 'No ETS Dispatch created',

  MSG_ROLE_GRANT_ACCESS:
    'Role Status indicates the status of access to MCC for the employee',

  MSG_LER_REQUEST_APPROVED_SUCCESS: 'LER {0} has been approved successfully.',
  MSG_LER_REQUEST_REJECTED_SUCCESS: 'LER {0} has been rejected successfully.',
  MSG_LER_REQUEST_SAVE_SUCCESS: 'LER has been saved successfully.',
  MSG_LER_REQUEST_CREATE_SUCCESS: 'LER Request successfully created',
  MSG_LER_REQUEST_UPDATE_SUCCESS: 'LER Request successfully updated',
  MSG_LER_REQUEST_UPDATE_SWITCHING_SUCCESS:
    'LER Request Switching successfully updated',
  MSG_LER_REQUEST_CHANGE_SUCCESS: 'Change Request submitted successfully',
  MSG_LER_REQUEST_DRAFT_CONFIRM: 'Are you sure you want to delete this draft?',
  MSG_LER_REQUEST_DRAFT_DELETE_SUCCESS: 'Draft has been deleted successfully.',

  MSG_LER_REQUEST_FORM_STEP_ERROR: 'Please complete the current step',
  MSG_LER_REQUEST_FORM_OUTAGE_DATE_ERROR:
    'Select at least one day for the Outage',

  MSG_LER_REQUEST_CONFIRM_DELETE:
    'Are you sure you want to delete this LER Request?',
  MSG_LER_REQUEST_CONFIRM_APPROVE:
    'Are you sure you want to approve this LER Request?',
  MSG_LER_REQUEST_CONFIRM_SCHEDULE:
    'Are you sure you want to schedule this LER Request?',
  MSG_LER_REQUEST_APPROVE_SUCCESS: 'Outage Request has been Approved',
  MSG_LER_REQUEST_APPROVE_CR_SUPERVISOR_SUCCESS:
    'Change request submitted successfully',
  MSG_LER_REQUEST_APPROVE_CR_SCHEDULER_SUCCESS:
    'Change request approved successfully',
  MSG_LER_REQUEST_STUDY_SUCCESS: 'Your Request has been updated to study',
  MSG_LER_REQUEST_STUDY_CONFIRM: 'Are you sure you want to set it to study?',
  MSG_LER_REQUEST_CONFIRM_REJECT:
    'Are you sure you want to reject this LER Request?',
  MSG_LER_REQUEST_REJECT_SUCCESS: 'Your Request has been Rejected',
  MSG_LER_REQUEST_REJECT_CR_SUCCESS: 'Change Request Rejected successfully',
  MSG_LER_REQUEST_REJECT_SCHED_SUCCESS: 'Request Rejected successfully',
  MSG_SYSTEM_CHANGE_CONFIRM_COMPLETION:
    'Are you sure you want to set the completion of this LER ({0})?',
  MSG_SYSTEM_CHANGE_CANCEL_COMPLETION:
    'Are you sure you want to clear the completion of this LER ({0})?',

  MSG_SUBMIT_LER_TO_CAISO: 'Do you wish to submit the LER details to CAISO?',
  MSG_SUBMIT_REJECT_LER_TO_CAISO: 'Proceed with cancellation to CAISO',
  MSG_SUBMIT_LER_TO_CAISO_SUCESS: 'Successfully sent to CAISO',
  MSG_LER_CHANGE_REQUEST:
    'The LER has a Change Request that requires attention. Please review to Approve or reject the Changes',
  MSG_LER_CHANGE_REQUEST_REQUESTOR:
    'The LER has a Change Request that requires attention.',
  MSG_LER_MAX_REVISION_REACHED:
    'This LER has reached the maximum number of allowed Change Requests. You have the option to Clone.',

  MSG_LER_REQUEST_SHORT_DESC_EXIST_ERROR: 'Short Description already exist.',
  MSG_LER_CREATE_CONFIRMATION: 'Are you sure you want to create the LER?',
  MSG_LER_REQUEST_APPROVE_CONFIRMATION:
    'Are you sure you want to approve the LER?',
  MSG_DELETE_SUCCESS: 'Deleted successfully',
  MSG_LER_REQUEST_DELETE_SUCCESS: 'LER deleted successfully',
  MSG_LER_REQUEST_SCHEDULE_SUCCESS: 'LER scheduled successfully',
  MSG_LER_REQUEST_DELETE_CHANGE_SUCCESS:
    'LER Change Request was deleted successfully',
  MSG_LER_REQUEST_CANCEL_CHANGE_SUCCESS:
    'Cancellation Request submitted successfully',
  MSG_LER_REQUEST_CANCEL_SUCCESS: 'LER Cancelled successfully',
  MSG_ADDITIONAL_VISITORS: 'Additional visitors entered the Substation.',
  MSG_ADD_FACILITY: 'Facility {0} has been created successfully.',
  MSG_EDIT_FACILITY: 'Facility {0} has been modified successfully.',
  MSG_DELETE_FACILITY: 'Facility {0} has been deleted.',
  MSG_READ_FACILITY: 'Error retrieving Facility {0}.',
  MSG_IN_USE_FACILITY:
    'There are existing outages attached to this facility. Only selected fields can be changed.',
  MSG_GROUP_NOT_ALLOWED: '(Groups can be added only during EDIT)',
  MSG_SUB_COUNT: 'Showing 1 - {0} of {0}',
  MSG_SUB_COUNT_UPDATE_SUCCESS:
    '{0} - {1} Substation Customer Counts has been updated successfully.',

  MSG_EMAIL_GROUP_SEND_MULTIPLE_SUCCESS: 'Emails sent successfully.',
  MSG_EMAIL_GROUP_RESEND_SUCCESS: 'Email resent successfully.',
  MSG_EMAIL_GROUP_REMOVE_SUCCESS: 'Email group remove successfully.',
  MSG_EMAIL_GROUP_EMAIL_BODY: 'Outage request [LER#: {0}] needs your review.',
  MSG_EMAIL_GROUP_APPROVE_SUCCESS: 'LER approved successfully.',
  MSG_EMAIL_GROUP_REJECT_SUCCESS: 'LER rejected successfully.',

  // Substation Count Upload
  MSG_FILE_NOT_CSV:
    'File is not comma separated - Please upload the data in correct format.',
  MSG_SUB_ID_INVALID: 'Line {0}: Substation ID should be an alphabet.',
  MSG_SUB_COUNT_NAN:
    'Line {0}: Substation Customer Count should be an integer value.',
  MSG_SUB_LOAD_NAN: 'Line {0}: Substation Load should be an integer value.',
  MSG_YEAR_NAN: 'Line {0}: Year should be an integer value.',
  MSG_COUNT_INVALID:
    'Line {0}: Substation Customer Count should be a maximum of 99,999.',
  MSG_LOAD_INVALID: 'Line {0}: Substation Load should be a maximum of 9,999.',
  MSG_YEAR_INVALID: 'Line {0}: Year should be between 2000-2100.',
  MSG_SUB_INVALID:
    'Line {0}: {1} is not a valid Substation ID. Please review your file.',
  MSG_NEGATIVE_COUNT:
    'Line {0}: Substation Customer Count should not be negative. Please review your file.',
  MSG_NEGATIVE_LOAD:
    'Line {0}: Substation Load should not be negative. Please review your file.',
  MSG_DECIMAL_COUNT:
    'Line {0}: Substation Customer Count should not have decimal. Please review your file.',
  MSG_DECIMAL_LOAD:
    'Line {0}: Substation Load should not have decimal. Please review your file.',
  MSG_BATCH_UPLOAD_SUCCESS:
    'Database was updated successfully with uploaded data.',
  MSG_FILE_EMPTY: 'File has no data.',
  MSG_FILE_HAS_BLANKS:
    'File is not populated in all 4 columns - Line {0} has missing data - ({1})',
  MSG_NO_SUB_ID: 'No Substation ID specified',
  MSG_NO_SUB_COUNT: 'No Customer Count value specified',
  MSG_NO_SUB_LOAD: 'No Customer Load specified',
  MSG_NO_YEAR: 'No Year specified',
  MSG_COLUMN_COUNT:
    'CSV file does not have 4 columns. Please see "Note" below.',

  // Version Control
  MSG_VERSION_CONTROL_MAX:
    'Exceed the maximum version selected [*Note: Maximum is 5].',

  MSG_DAILY_REPORT_UPDATE_SUCCESS:
    'Actual times have been updated for LER # {0}',

  // Change Request
  MSG_CHANGE_REQUEST_APPROVE_NOTE:
    'LER needs to be approved before the change request',

  MSG_SEND_OMS_REQUEST_COMPLETED: 'OMS Request Sending Completed.',
  MSG_OMS_CAISO_RESPONSE_STATUS: '({0}) LER #{1} {2}',
  MSG_OMS_EDIT_SUCCESSFUL: 'OMS Outage #{0} has been edited successfully',

  MSG_ADD_RELATED_LER_SUCCESS: 'New Related LER {0} added  successfully',
  MSG_REMOVE_RELATED_LER_SUCCESS: 'Related LER {0} removed  successfully',

  MSG_RADIALIZED_SUB_TOTAL_SAIDI: 'Total Possible T-SAIDI Impact : {0} ',

  MSG_EMAIL_GROUP_NO_ACCESS:
    'This information is not available or you no longer have access to this page.',

  // List of Short Description Categories
  MSG_SHORT_DESCRIPTION_REQUIRED: 'At least one description is required.',
  MSG_SHORT_DESCRIPTION_CATEGORY_REQUIRED: 'Category Name is required',
  MSG_SHORT_DESCRIPTION_CATEGORY_EXISTS:
    'Category Name {0} already exists. Choose a different name',
  MSG_SHORT_DESCRIPTION_CATEGORY_ADD_SUCCESS:
    'New Category {0} and Description have been added successfully',
  MSG_SHORT_DESCRIPTION_CATEGORY_DELETE_SUCCESS:
    'Job Info Category and Descriptions have been deleted successfully',
  MSG_SHORT_DESCRIPTION_DELETE_SUCCESS:
    'Description {0} has been deleted successfully',
  MSG_SHORT_DESCRIPTION_CATEGORY_UPDATE_SUCCESS:
    'Job Info Category and Descriptions have been updated successfully',

  MSG_OUTAGE_CALENDAR_NO_DATA: 'No calendar data found',
  MSG_EMPTY_VISITOR_UPLOADED_LIST:
    'Visitor files has been deleted. Click Save to proceed.',
  MSG_SUBSTATION_KEY_PROCEDURE:
    '<strong>Substation Key Procedure SUB4025</strong>',
  MSG_KEARNY_MAINTENANCE:
    '<a href={0} target="blank"> <u> <strong>Kearny Maintenance and Operations Standard Operating Procedures</strong></u></a>',
  MSG_KEY_REQUEST:
    '&nbsp;To request keys other than Schlage 30 or 40 series keys, please see {0}, found in the {1}',
  MSG_FOLLOWING_TRAINING:
    '30 and 40 Key Holders must have the following training:',
  MSG_HOME_PAGE_MANAGE: 'MCC is managed by Electric Grid Operations.',
  MSG_HOME_PAGE_NOTE:
    'This internal web site, including all content posted hereon,belongs to SDG&E, and is reserved solely for use by its employees and designated representatives. Any person or entity accessing this site without permission or any use of the posted content without consent, is strictly prohibited. SDG&E will take appropriate actions to identify or prevent any improper use.',
  MSG_SCHLAGE_30: ['Schlage 30', 'No', 'Yes', 'No'],
  MSG_SCHLAGE_40: ['Schlage 40', 'Yes', 'Yes', 'Yes'],
  MSG_KEY_TYPE: ['Schlage 10, 20', 'Facilities'],
  MSG_ISSUED_BY: ['Medeco XT', 'Corporate Security'],

  MSG_CAISO_COMMUNICATION_FAILED_SUBMIT:
    'CAISO Communication is not complete. Requests have been queued. Check New LER Page for success or failure status.',
  MSG_CAISO_COMMUNICATION_FAILED_RETRIEVE: 'Error Retrieving Data from CAISO',
  MSG_TROUBLE_TICKET_DOWNLOAD_NOTE: 'Begin date is required.',
  MSG_TROUBLE_TICKET_DOWNLOAD_NOTE_DATE_RANGE: 'Date Range is required.',

  // TRAINERS
  MSG_TRAINERS_UPDATED_SUCCESSFULLY: 'Updated Successfully',
  // PENDING CAISO
  MSG_PENDING_CAISO_REMOVED_FROM_LIST:
    'Are you sure you want to remove this LER from Pending CAISO List?',
  MSG_PENDING_CAISO_ADD_TO_LIST:
    'Do you want to put this into the pending California ISO list?',
  MSG_UPDATE_LER: 'Are you sure you want to update this LER request?',
  MSG_CHANGE_LER:
    'This update will create a Change Request for this LER.',
};
