import React, { useCallback, useRef, useState } from 'react';
import { ATMButton, ATMDropdown, ATMModal } from 'shared-it-appmod-ui';
import { useAuthorizedListContext } from 'src/contexts/authorized-list.context';
import { authorizedListActionTypes } from 'src/ducks/authorized-list.duck';
import {
  IAuthorizedList,
  IAuthorizedListForm,
} from 'src/models/authorized-list.model';
import { ISubCategory } from 'src/models/sub-category.model';
import { getAuthorizedListStatus } from 'src/selectors/authorized-list.selector';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import AuthorizedListForm from '../authorized-list-form/authorized-list-form.component';

type IProp = {
  data?: IAuthorizedList;
  entryView?: boolean;
  entryText?: string;
  trainingData?: any;
  subCategoryList: ISubCategory[];
  trainingHistory?: boolean;
  subCategoryStatus: boolean;
};

const AuthorizedListEdit: React.FC<IProp> = ({
  data,
  entryView,
  entryText,
  subCategoryList,
  trainingData,
  trainingHistory,
  subCategoryStatus,
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const [type, setType] = useState<string>();
  const { state, actions } = useAuthorizedListContext();
  const formRef = useRef<HTMLFormElement>(null);
  const status = getAuthorizedListStatus(
    state,
    authorizedListActionTypes.AUTHORIZED_LIST_DATA_UPDATE
  );
  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleOpen = useCallback(
    (actionType?: string) => {
      setType(actionType);
    },
    [actions, setType]
  );

  const handleSubmit = useCallback(
    async (formData: IAuthorizedListForm) => {
      if (data) {
        const response = await actions.updatePUT(formData?.authListId, {
          ...formData,
        });

        if (!response.error) {
          const empData = await actions.empDataGET(data.empId);
          setType(undefined);

          let empFullName = '<unavailable>';

          if (!empData.error && empData.payload?.fullName) {
            empFullName = empData.payload?.fullName;
          }

          ToastSuccess(
            `Training Record successfully modified for ${empFullName}`
          );
        } else {
          ToastError(
            `Error in updating the training record. Error: ${response.error.message}`
          );
        }
      }
    },
    [data, actions]
  );

  if (!data) {
    return null;
  }

  return (
    <ATMModal
      open={!!type}
      size="small"
      trigger={
        // eslint-disable-next-line no-nested-ternary
        trainingHistory ? (
          <ATMDropdown.Item
            content="Edit"
            icon="edit"
            onClick={() => handleOpen(entryText)}
          />
        ) : entryView ? (
          <a role="button" onClick={() => handleOpen(entryText)}>
            Modify
          </a>
        ) : (
          <ATMButton icon="edit" onClick={() => handleOpen(entryText)} />
        )
      }
    >
      <ATMModal.Header>
        Edit {type} - {data?.fullName}
      </ATMModal.Header>
      <ATMModal.Content>
        <AuthorizedListForm
          defaultValues={data}
          formRef={formRef}
          handleSubmit={handleSubmit}
          handleEnable={(value) => setIsDirty(value)}
          authType={type}
          subCategoryList={subCategoryList}
          trainingData={trainingData}
          subCategoryStatus={subCategoryStatus}
        />
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton secondary content="Cancel" onClick={() => handleOpen()} />
        <ATMButton
          primary
          content="Save"
          disabled={!isDirty || status.fetching}
          onClick={handleClick}
          loading={status.fetching}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default AuthorizedListEdit;
