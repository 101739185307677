import React from 'react';
import { ATMButton, ATMDropdown, ATMModal } from 'shared-it-appmod-ui';
import { IContractorFirm } from 'src/models/contractor-firm.model';
import style from '../contractor-firm.module.scss';

type IProp = {
  data: IContractorFirm;
  isOpen: boolean;
  loading?: boolean;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: () => void;
};

const ContractorFirmDelete: React.FC<IProp> = ({
  data,
  isOpen,
  loading = false,
  handleOpen,
  handleSubmit,
}) => (
  <ATMModal
    open={isOpen}
    size="tiny"
    trigger={
      <ATMDropdown.Item
        icon="trash alternate outline"
        content="Deactivate"
        className={style.manageDropdown}
        onClick={() => handleOpen(true)}
      />
    }
  >
    <ATMModal.Header>Deactivate Firm</ATMModal.Header>
    <ATMModal.Content>
      <p>
        {'Are you sure you want to deactivate '}
        <strong>{data.fullName}</strong>?
      </p>
    </ATMModal.Content>
    <ATMModal.Actions>
      <ATMButton secondary content="Cancel" onClick={() => handleOpen(false)} />
      <ATMButton
        negative
        content="Confirm"
        onClick={handleSubmit}
        disabled={loading}
        loading={loading}
      />
    </ATMModal.Actions>
  </ATMModal>
);

export default ContractorFirmDelete;
