import React, { useState } from 'react';
import { ATMButton, ATMDropdown, ATMModal } from 'shared-it-appmod-ui';
import {
  ContractorFormSchema,
  IContractor,
  IContractorForm,
} from 'src/models/contractor.model';
import ContractorForm from '../contractor-form/contractor-form.component';

type IProps = {
  defaultValues: IContractor;
  isOpen: boolean;
  loading: boolean;
  isStatusChanged?: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  handleClick: () => void;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsStatusChanged?: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (data: IContractorForm) => void;
};

const ContractorEditView: React.FC<IProps> = ({
  defaultValues,
  isOpen,
  loading,
  formRef,
  isStatusChanged,
  handleOpen,
  handleClick,
  handleSubmit,
  setIsStatusChanged,
}) => {
  const [isDirty, setIsDirty] = useState(false);
  return (
    <ATMModal
      open={isOpen}
      size="small"
      trigger={
        <ATMDropdown.Item
          icon="edit"
          onClick={() => handleOpen(true)}
          content="Edit"
          compact="true"
        />
      }
    >
      <ATMModal.Header>Edit Contractor</ATMModal.Header>
      <ATMModal.Content>
        <ContractorForm
          defaultValues={ContractorFormSchema.cast(defaultValues)}
          formRef={formRef}
          handleSubmit={handleSubmit}
          handleEnable={(value) => setIsDirty(value)}
          setIsStatusChanged={setIsStatusChanged}
          isEdit
        />
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content="Cancel"
          onClick={() => {
            if (setIsStatusChanged) {
              setIsStatusChanged(false);
            }
            handleOpen(false);
          }}
        />
        <ATMButton
          primary
          content="Submit"
          disabled={!(isDirty || isStatusChanged) || loading}
          onClick={handleClick}
          loading={loading}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default ContractorEditView;
