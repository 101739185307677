import React, { useCallback, useState } from 'react';
import { useContractorFirmContext } from 'src/contexts/contractor-firm.context';
import { IContractorFirm } from 'src/models/contractor-firm.model';
import { getContractorFirmStatus } from 'src/selectors/contractor-firm.selector';
import { contractorFirmActionTypes } from 'src/ducks/contractor-firm.duck';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import ContractorFirmActivateView from './contractor-firm-actvate.view';

type IProp = {
  data: IContractorFirm;
};

const ContractorFirmActivate: React.FC<IProp> = ({ data }) => {
  const { state, actions } = useContractorFirmContext();
  const [isOpen, setIsOpen] = useState(false);

  const status = getContractorFirmStatus(
    state,
    contractorFirmActionTypes.CONTRACTOR_FIRM_DATA_ACTIVATE
  );

  const handleSubmit = useCallback(async () => {
    if (data.contracShortNm) {
      const response = await actions.dataACTIVATE(data.contracShortNm);

      if (!response.error) {
        setIsOpen(false);
        actions.listGET({
          limit: 0,
          page: 1,
          sort: 'contracShortNm',
          order: 'ascending',
        });
        ToastSuccess(`Firm ${data.contracShortNm} activated successfully`);
      } else {
        ToastError(response.error.message);
      }
    }
  }, [actions, data]);

  return (
    <ContractorFirmActivateView
      data={data}
      isOpen={isOpen}
      loading={status.fetching}
      handleOpen={setIsOpen}
      handleSubmit={handleSubmit}
    />
  );
};

export default ContractorFirmActivate;
