import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ATMLabel,
  IORGDataTableColumn,
  IORGDataTableQueryState,
  IORGDataTableUpdateOptions,
  ORGDataTable,
} from 'shared-it-appmod-ui';
import Status from 'src/components/atoms/status/status.component';
import {
  AccessRole,
  LERRequestChangeStatus,
  LERRequestPage,
  LERRequestStatus,
} from 'src/constants';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { getEmployeeName } from 'src/helpers/employee.helper';
import { FutureTimeToRoundedInterval, INTERVAL_UNIT_MAP, IntervalToString } from 'src/helpers/interval.helper';
import { getLerRequestChangeFilters } from 'src/helpers/ler-request.helper';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import { hasRole } from 'src/libraries/access.library';
import Lang from 'src/libraries/language';
import { ILerRequestItem } from 'src/models/ler-request.model';
import {
  getLerRequestEndDate,
  getLerRequestStartDate,
} from 'src/selectors/ler-request.selector';
import styles from '../../ler-request.module.scss';

export interface LerRequestListChangeProps {
  intervalMS?: number;
}
const DEFAULT_INTERVAL = 1000 * 60 * 5; // 5 minutes

const LerRequestListChange = ({ intervalMS = DEFAULT_INTERVAL }: LerRequestListChangeProps) => {
  const {
    params: { lerView },
    getUrl,
  } = useLocationParams([LERRequestPage.View]);
  const { state, actions } = useLerRequestContext();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState<Partial<IORGDataTableQueryState>>({});

    const [lastRefreshTime, setLastRefreshTime] = useState<Date>();
    const [nextRefreshTime, setNextRefreshTime] = useState<Date>(() => new Date(new Date().getTime() + intervalMS));
    const tick = useReducer(() => ({}), {})[1];
  
    const timeToReload = FutureTimeToRoundedInterval(nextRefreshTime);
    const timeToReloadString = IntervalToString(timeToReload);
    const tickMS = Math.max(INTERVAL_UNIT_MAP[timeToReload.intervalUnit]/60, 500);
  
    const lastRefreshString = lastRefreshTime?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) ?? '--:--';
  
  const handleFetch = useCallback(
    async (
      query: Partial<IORGDataTableQueryState>,
      options?: IORGDataTableUpdateOptions
    ) => {
      if (!options || (options && options.action) || !state.changeList.length) {
        setLoading(true);
      }

      setParams(query);

      await actions.listChangeGET({
        ...query,
        filters: getLerRequestChangeFilters(query.filters),
      });

      const currentTime = new Date();
      setLastRefreshTime(currentTime);
      setNextRefreshTime(new Date(currentTime.getTime() + intervalMS));

      setLoading(false);
    },
    [actions, setParams, setLoading, intervalMS]
  );

  // This will refresh the list if the detail/edit page is closed
  useEffect(() => {
    if (refresh && !lerView) {
      setRefresh(false);
      handleFetch(params);
    }
  }, [refresh, lerView, params, setRefresh, handleFetch]);

  useEffect(() => {
    if (lerView) {
      setRefresh(true);
    }
  }, [lerView, setRefresh]);

  // Refresh the list on an interval
  useEffect(() => {
    const interval = setInterval(() => {
      if (nextRefreshTime.getTime() <= new Date().getTime()) {
        handleFetch(params);
      }
      tick();
    }, tickMS);

    return () => clearInterval(interval);
  }, [tickMS, nextRefreshTime, handleFetch, params, tick]);

  const columns: IORGDataTableColumn<ILerRequestItem>[] = [
    {
      index: 'requestId',
      title: Lang.LBL_LER,
      render: (_, value) => (
        <Link
          to={getUrl({
            [LERRequestPage.View]: value.requestId,
          })}
        >
          {value.requestId}
        </Link>
      ),
    },
    {
      index: 'requestStat',
      title: 'Request Status',
      render: (_, value) => (
        <Status
          language={Lang.LER_REQUEST_STATUS}
          value={value.requestStat}
          label
        />
      ),
    },
    {
      index: 'changeReqStat',
      title: 'CR Status',
      render: (_, value) =>
        value.changeReqStat && (
          <ATMLabel className={styles.labelTransparent}>
            {value.changeReqStat}
          </ATMLabel>
        ),
    },
    {
      index: 'outageFacility.outageType.outageTypeDesc',
      title: Lang.LBL_AUTHORIZATION_TYPE,
      render: (_, value) => value.outageFacility?.outageType?.outageTypeDesc,
    },
    {
      index: 'requestorName',
      title: Lang.LBL_REQUESTOR_NAME,
      render: (_, value) =>
        value.requestor ? getEmployeeName(value.requestor) : null,
    },
    {
      index: 'actionPendingBy',
      sortable: false,
      title: Lang.LBL_ACTION_PENDING_BY,
      isVisible: !hasRole(AccessRole.MCC_SUBMITTING_SUPERVISOR),
      render: (_, value) => {
        // const isScheduler = hasRole(AccessRole.MCC_OUTAGE_SCHEDULER)
        //   ? 'Outage Coordinator'
        //   : '-';
        let other = '-';
        const employee = () => {
          let emp = value.requestor;
          if (hasRole(AccessRole.MCC_OUTAGE_REQUESTOR)) {
            if (
              value.requestStat !== LERRequestStatus.Scheduled &&
              value.changeReqStat === LERRequestChangeStatus.Requested
            ) {
              emp = value.supervisor;
            }

            if (
              value.requestStat === LERRequestStatus.Scheduled &&
              value.changeReqStat === LERRequestChangeStatus.Requested
            ) {
              emp = null;
              other = 'Outage Coordinator';
            }
          }
          if (hasRole(AccessRole.MCC_OUTAGE_SCHEDULER)) {
            emp = value.schedulerAssign;
            if (value.requestStat === LERRequestStatus.Rejected) {
              if (value.changeReqStat === LERRequestChangeStatus.Requested) {
                emp = value.requestor;
              }
              if (value.changeReqStat === LERRequestChangeStatus.Submitted) {
                emp = value.supervisor;
              }
            }
            if (
              value.requestStat === LERRequestStatus.Scheduled &&
              value.changeReqStat === LERRequestChangeStatus.Requested
            ) {
              emp = null;
              other = 'Outage Coordinator';
            }
            if (
              value.requestStat !== LERRequestStatus.Submitted &&
              value.changeReqStat === LERRequestChangeStatus.Submitted
            ) {
              emp = null;
              other = 'Outage Coordinator';
            }
          }
          return emp;
        };

        return <span>{employee() ? getEmployeeName(employee()) : other}</span>;
      },
    },
    {
      index: 'outageFacility.facility.outgFacNm',
      title: 'Facility Name',
      render: (_, value) => value.outageFacility?.facility?.outgFacNm,
    },
    {
      index: 'substation',
      title: 'Substation',
      render: (_, value) =>
        value.outageFacility?.facility?.facTypId !==
          Lang.FACILITY_TYPE_ID_BACKEND.LINE && value.outageFacility?.substation
          ? value.outageFacility?.substation?.name
          : '',
    },
    {
      index: 'startDate',
      title: 'Start Date',
      render: (_, value) => getLerRequestStartDate(value.outageDates),
    },
    {
      index: 'endDate',
      title: 'End Date',
      render: (_, value) => getLerRequestEndDate(value.outageDates),
    },
    {
      index: 'days',
      title: 'Days',
      sortable: false,
    },
    {
      index: 'assignedTo',
      title: 'Assigned to',
      sortable: false,
      render: (_, value) => {
        if (value.schedulerAssign) {
          return `${value.schedulerAssign.firstName} ${value.schedulerAssign.lastName}`;
        }

        return value.schedulerAssignId || Lang.LBL_UNASSIGNED;
      },
    },
  ];

  return (
    <>
      <ORGDataTable
        celled={false}
        columns={columns}
        // data={
        //   hasRole(AccessRole.MCC_OUTAGE_REQUESTOR)
        //     ? state?.changeList.filter(
        //         (val) =>
        //           !moment(getLerRequestEndDate(val.outageDates)).isBefore(
        //             moment(new Date())
        //           )
        //       )
        //     : state.changeList
        // }
        data={state.changeList}
        onChange={handleFetch}
        loading={loading}
        total={state.changeTotal}
        noDataText={<p>No LER Change Request.</p>}
        sortable
      >
        {() => ({
          toolbars: {
            caisoLabel: () => (
                  <><b>Last Refresh</b> {lastRefreshString} | <b>Next refresh</b> in {timeToReloadString}</>
              ),
          },
        })}
      </ORGDataTable>
    </>
  );
};

export default LerRequestListChange;
