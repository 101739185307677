import React, { useEffect } from 'react';
import {
  ATMForm,
  ATMGrid,
  ATMField,
  ATMInput,
  ATMDropdown,
  ATMToggle,
} from 'shared-it-appmod-ui';
import { contractorFirmActionTypes } from 'src/ducks/contractor-firm.duck';
import { getContractorFirmStatus } from 'src/selectors/contractor-firm.selector';
import LabelRequired from 'src/components/atoms/label-required/label-required.component';
import { useContractorFirmContext } from 'src/contexts/contractor-firm.context';
import {
  IContractorForm,
  ContractorFormSchema,
} from 'src/models/contractor.model';
import style from '../../contractor-firm/contractor-firm.module.scss';

type IProp = {
  formRef?: React.RefObject<HTMLFormElement>;
  isEdit: boolean;
  defaultValues?: Partial<IContractorForm>;
  setIsStatusChanged?: (value: boolean) => void;
  handleSubmit: (data: IContractorForm) => void;
  handleEnable: (value: boolean) => void;
};

const ContractorForm: React.FC<IProp> = ({
  formRef,
  isEdit = false,
  defaultValues,
  handleSubmit,
  handleEnable,
  setIsStatusChanged,
}) => {
  const { state: contractorFirmsState, actions: contractorFirmsActions } =
    useContractorFirmContext();
  useEffect(() => {
    if (!contractorFirmsState.list || contractorFirmsState.list.length === 0) {
      contractorFirmsActions?.listGET({ page: 1, limit: 0 });
    }
  }, []);
  const firmsStatus = getContractorFirmStatus(
    contractorFirmsState,
    contractorFirmActionTypes.CONTRACTOR_FIRM_LIST_READ
  );
  return (
    <ATMForm
      ref={formRef}
      onSubmit={handleSubmit}
      mode="onChange"
      defaultValues={ContractorFormSchema.cast(defaultValues)}
      validationSchema={ContractorFormSchema}
    >
      {({ control, formState: { errors, isDirty }, setValue }) => {
        handleEnable(isDirty);
        return (
          <ATMGrid>
            <ATMGrid.Row columns={4}>
              <ATMGrid.Column width={5} className="reqField">
                <ATMField
                  as={ATMInput}
                  label={<LabelRequired>Last Name</LabelRequired>}
                  name="lastName"
                  control={control}
                  rules={{ minLength: 2 }}
                  error={errors.lastName}
                />
              </ATMGrid.Column>
              <ATMGrid.Column width={5}>
                <ATMField
                  label="Middle Initial"
                  as={ATMInput}
                  name="middleName"
                  control={control}
                  error={errors.middleName}
                />
              </ATMGrid.Column>
              <ATMGrid.Column width={5}>
                <ATMField
                  as={ATMInput}
                  label={<LabelRequired>First Name</LabelRequired>}
                  name="firstName"
                  control={control}
                  rules={{ minLength: 2 }}
                  error={errors.firstName}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row columns={3}>
              <ATMGrid.Column width={5}>
                <ATMField
                  label="Email"
                  as={ATMInput}
                  name="email"
                  control={control}
                  error={errors.email}
                />
              </ATMGrid.Column>
              <ATMGrid.Column width={5}>
                {isEdit &&
                contractorFirmsState.list.find(
                  (val) =>
                    val.contracShortNm === defaultValues?.contracShortNm &&
                    val.activeFlag === false
                ) ? (
                  <ATMField
                    as={ATMDropdown}
                    label={<LabelRequired>Firm Name</LabelRequired>}
                    selection
                    loading={firmsStatus.fetching}
                    name="contracShortNm"
                    placeholder="Select"
                    options={contractorFirmsState.list.map((value) => ({
                      key: value.contracShortNm,
                      value: value.contracShortNm,
                      text: value.contracShortNm,
                    }))}
                    onChange={([_, { value }]) => value}
                    control={control}
                    error={errors.contracShortNm}
                    selectOnBlur={false}
                    search
                    clearable
                  />
                ) : (
                  <ATMField
                    as={ATMDropdown}
                    label={<LabelRequired>Firm Name</LabelRequired>}
                    selection
                    loading={firmsStatus.fetching}
                    name="contracShortNm"
                    placeholder="Select"
                    options={contractorFirmsState.list
                      .filter(
                        (val) => val.activeFlag || val.activeFlag === null
                      )
                      .map((value) => ({
                        key: value.contracShortNm,
                        value: value.contracShortNm,
                        text: value.contracShortNm,
                      }))}
                    onChange={([_, { value }]) => value}
                    control={control}
                    error={errors.contracShortNm}
                    selectOnBlur={false}
                    search
                    clearable
                  />
                )}
              </ATMGrid.Column>
              {isEdit ? (
                <ATMGrid.Column width={5}>
                  <div>
                    <p className={style.header}>Inactive</p>
                    <ATMToggle
                      fluid
                      name="employeeStat"
                      control={control}
                      style={{ marginTop: '.4em' }}
                      defaultChecked={defaultValues?.employeeStat === 'I'}
                      onChange={(_, data) => {
                        if (setIsStatusChanged) {
                          setIsStatusChanged(true);
                        }
                        if (data.checked) {
                          setValue('employeeStat', 'I');
                        } else {
                          setValue('employeeStat', 'A');
                        }
                        return data.checked;
                      }}
                      error={errors.employeeStat}
                      selectOnBlur={false}
                    />
                  </div>
                </ATMGrid.Column>
              ) : (
                <ATMGrid.Column width={5} />
              )}
            </ATMGrid.Row>
          </ATMGrid>
        );
      }}
    </ATMForm>
  );
};

export default ContractorForm;
