import { httpClient } from 'src/libraries/http.library';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import {
  IContractorFirmForm,
  ContractorFirmListPayloadSchema,
  ContractorFirmPayloadSchema,
  ContractorFirmChangeStatusPayloadSchema,
} from 'src/models/contractor-firm.model';

const client = httpClient();
const endpoint = 'contractor-firm';

const services = {
  dataGET: async (id: string) => {
    return client.get(`/${endpoint}/${id}`, {}, ContractorFirmPayloadSchema);
  },

  listGET: async (data: Partial<IORGDataTableQueryState> = {}) => {
    const params: Record<string, any> = {
      ...data,
    };
    if (params.order) {
      params.order = params.order === 'ascending' ? 'asc' : 'desc';
    }

    return client.get(`/${endpoint}`, params, ContractorFirmListPayloadSchema);
  },

  createPOST: async (data: IContractorFirmForm) => {
    return client.post(`/${endpoint}`, data, ContractorFirmPayloadSchema);
  },

  updatePUT: async (id: string, data: IContractorFirmForm) => {
    // Encode because ID can have "slash" which results to an incorrect URL
    const encodedId = encodeURIComponent(id);
    return client.put(
      `/${endpoint}/${encodedId}`,
      data,
      ContractorFirmPayloadSchema
    );
  },

  dataDELETE: async (id: string) => {
    // Encode because ID can have "slash" which results to an incorrect URL
    const encodedId = encodeURIComponent(id);
    return client.delete(
      `/${endpoint}/${encodedId}`,
      ContractorFirmChangeStatusPayloadSchema
    );
  },

  dataACTIVATE: async (id: string) => {
    // Encode because ID can have "slash" which results to an incorrect URL
    const encodedId = encodeURIComponent(id);
    const data = {
      contracShortNm: id,
      activeFlag: true,
    };

    return client.put(
      `/${endpoint}/${encodedId}`,
      data,
      ContractorFirmPayloadSchema
    );
  },
};

export default services;
