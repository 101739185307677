import React from 'react';
import { ATMDatePicker } from 'shared-it-appmod-ui';
import { format24hTime } from 'src/libraries/moment.library';
import { ILerRequestForm } from 'src/models/ler-request.model';
import moment from 'moment';
import { ISortResponse } from 'src/models/switching.model';

type IProps = {
  name?: any;
  index: number;
  control: any;
  defaultValue?: ISortResponse;
  disabled?: boolean;
  handleEmptyValue: (
    timeIndex: any,
    timeValue: any,
    record: ISortResponse,
    isDate: boolean
  ) => void;
  callback?: () => void;
  reset?: () => void | undefined;
  setValue?: (name: any, value: any) => void;
  setError?: (name: any, value: any) => void;
  getValues: () => ILerRequestForm;
  handleEnable?: (param: any) => void;
  update: (index: number, value: any) => void;
  register?: React.Dispatch<React.SetStateAction<any>>;
  clearErrors?: React.Dispatch<React.SetStateAction<any>>;
};

const LERSwitchingDateInput: React.FC<IProps> = ({
  getValues,
  update,
  handleEmptyValue,
  index,
  name,
  disabled,
  // setError,
  // clearErrors,
}) => {
  // const [firstDt, setfirstDt] = useState<Date | undefined>(
  //   getValues()?.sortResponses?.[index]?.date as Date
  // );
  return (
    <>
      <ATMDatePicker
        size="small"
        format="MM/DD/YYYY"
        placeholder="MM/DD/YYYY"
        disabled={disabled}
        name={name}
        minDate={moment(new Date(), 'MM/DD/YYYY').startOf('day').toDate()}
        value={getValues()?.sortResponses?.[index]?.date}
        onChange={(_, { value }) => {
          const sortRecord = getValues()?.sortResponses?.[index];
          handleEmptyValue(
            index,
            value,
            {
              ...sortRecord,
              date: value,
            } as any,
            true
          );
          if (value) {
            // const dateValue = formatDate(value as Date, 'MM/DD/YYYY');

            // if (setError && moment(dateValue).isBefore(firstDt, 'day')) {
            //   update(index, {
            //     ...sortRecord,
            //     date: value,
            //   });
            //   setError(name, {
            //     message: 'Past date is invalid',
            //   });
            // } else if (setError && moment(dateValue).isAfter(firstDt, 'year')) {
            //   update(index, {
            //     ...sortRecord,
            //     date: value,
            //   });
            //   setError(name, {
            //     message: `The entered date is "${dateValue}". Please ensure this is the intended date.`,
            //   });
            // } else {
            //   if (clearErrors) clearErrors(name);
            // }

            const timeVal = format24hTime(
              getValues()?.sortResponses?.[index]?.sortTime
            ) as any;
            if (timeVal) {
              const [hour, minute] = timeVal && timeVal.split(':');
              update(index, {
                ...sortRecord,
                date: value,
                sortTime: moment(value as any)
                  .set({
                    hour,
                    minute,
                  })
                  .toDate(),
              });

              // setfirstDt(value as Date);
            }
          }
          return value;
        }}
      />
    </>
  );
};

export default LERSwitchingDateInput;
