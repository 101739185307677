import React, { useState } from 'react';
import {
  ATMButton,
  ATMModal,
  IORGDataTableColumn,
  ORGDataTable,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import {
  ILerRequest,
  ILerRequestOutageReject,
} from 'src/models/ler-request.model';
import { getEmployeeName } from 'src/helpers/employee.helper';
import moment from 'moment';
import { RejectionType } from 'src/constants';
import styles from './ler-request-rejection-notes.module.scss';

type IProps = {
  data: ILerRequest;
  fromForm?: boolean;
};

const formatDateTime = (date: any) => {
  const formattedDate = moment(date).format('MM/DD/YYYY h:mm:ss A');
  return formattedDate ?? '';
};

const getRejectionType = (rejectionType: any) => {
  let rejectionTypeValue: any = 'Outage Request';
  switch (rejectionType) {
    case RejectionType.ChangeRequest: {
      rejectionTypeValue = 'Change Request';
      break;
    }
    case RejectionType.OutageRequest: {
      rejectionTypeValue = 'Outage Request';
      break;
    }
    default:
      break;
  }
  return rejectionTypeValue;
};

const LERRejectionNotes: React.FC<IProps> = ({ data, fromForm = false }) => {
  const [isOpen, setIsOpen] = useState(false);

  const columns: IORGDataTableColumn<ILerRequestOutageReject>[] = [
    {
      index: 'reasonDesc',
      title: Lang.LBL_REASON_OF_REJECTION,
      render: (_, value) => value.reason?.reasonDesc ?? '-',
    },
    {
      index: 'rejectionReason',
      title: Lang.LBL_REJECTION_NOTE,
      render: (_, value) => value.rejectionReason ?? '-',
    },
    {
      index: 'rejectionDate',
      title: 'Date & Time',
      render: (_, value) =>
        value.rejectionDate ? formatDateTime(value.rejectionDate) : '-',
    },
    {
      index: 'rejector',
      title: 'Rejected By',
      render: (_, value) =>
        value.rejector ? getEmployeeName(value.rejector) : '-',
    },
    {
      index: 'rejectionType',
      title: 'Rejection Type',
      render: (_, value) => getRejectionType(value.rejectionType),
    },
  ];
  return (
    <>
      <ATMModal
        open={isOpen}
        size="small"
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        trigger={
          <ATMButton
            type="button"
            className={fromForm ? styles.actionBtnFrm : styles.actionBtn}
            content="Show Rejection Notes"
          />
        }
      >
        <ATMModal.Header>{Lang.LBL_REJECTION_NOTE}</ATMModal.Header>
        <ATMModal.Content>
          <ORGDataTable
            celled
            columns={columns}
            sortable
            data={data?.rejectNotes as ILerRequestOutageReject[]}
            noDataText={<p>No Rejection Notes.</p>}
            rowsPerPageOptions={[5]}
            counter
          />
        </ATMModal.Content>
        <ATMModal.Actions>
          <ATMButton
            primary
            content="Close"
            onClick={() => setIsOpen(false)}
            type="button"
          />
        </ATMModal.Actions>
      </ATMModal>
    </>
  );
};

export default LERRejectionNotes;
