import React, { useCallback } from 'react';
import { useContractorFirmContext } from 'src/contexts/contractor-firm.context';
import { contractorFirmActionTypes } from 'src/ducks/contractor-firm.duck';
import useStatusTab from 'src/hooks/status-tab.hook';
import { getContractorFirmStatus } from 'src/selectors/contractor-firm.selector';
import ContractorFirmListView from './contractor-firm-list.view';

const ContractorFirmList: React.FC = () => {
  const { state, actions } = useContractorFirmContext();
  const { currentTab } = useStatusTab('contractorFirm');
  const status = getContractorFirmStatus(
    state,
    contractorFirmActionTypes.CONTRACTOR_FIRM_LIST_READ
  );

  const handleFetch = useCallback(() => {
    actions.listGET({
      limit: 0,
      page: 1,
      sort: 'contracShortNm',
      order: 'ascending',
    });
  }, [actions]);

  return (
    <ContractorFirmListView
      data={state.list}
      loading={status.fetching}
      handleFetch={handleFetch}
      currentTab={currentTab}
    />
  );
};

export default ContractorFirmList;
