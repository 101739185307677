import moment from 'moment';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ATMIcon,
  ATMLabel,
  IORGDataTableColumn,
  IORGDataTableQueryState,
  IORGDataTableUpdateOptions,
  ORGDataTable,
} from 'shared-it-appmod-ui';
import OutageDay from 'src/components/atoms/outage-day/outage-day.component';
import OutageStatus from 'src/components/atoms/outage-status/outage-status.component';
import QueueSummary from 'src/components/molecules/queue-summary/queue-summary.component';
import {
  AccessRole,
  LERNotificationStatus,
  LERRequestChangeStatus,
  // LERRequestChangeStatus,
  LERRequestPage,
  LERRequestStatus,
} from 'src/constants';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { getEmployeeName } from 'src/helpers/employee.helper';
import { FutureTimeToRoundedInterval, INTERVAL_UNIT_MAP, IntervalToString } from 'src/helpers/interval.helper';
import {
  getLerRequestNewFilters,
  hasEditAccess,
} from 'src/helpers/ler-request.helper';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import { hasRole } from 'src/libraries/access.library';
import Lang from 'src/libraries/language';
import { ILerRequestItem } from 'src/models/ler-request.model';
import {
  getLerRequestEndDate,
  getLerRequestStartDate,
} from 'src/selectors/ler-request.selector';
import LERRequestAssignScheduler from '../../ler-request-assign-scheduler/ler-request-assign-scheduler.component';
import styles from '../../ler-request.module.scss';

export interface LERRequestListNewProps {
  intervalMS?: number;
}
const DEFAULT_INTERVAL = 1000 * 60 * 5; // 5 minutes

const LERRequestListNew = ({ intervalMS = DEFAULT_INTERVAL }: LERRequestListNewProps) => {
  const {
    params: { lerView, lerEdit },
    getUrl,
  } = useLocationParams([LERRequestPage.View, LERRequestPage.Edit]);
  const { state, actions } = useLerRequestContext();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState<Partial<IORGDataTableQueryState>>({});

  const [lastRefreshTime, setLastRefreshTime] = useState<Date>();
  const [nextRefreshTime, setNextRefreshTime] = useState<Date>(() => new Date(new Date().getTime() + intervalMS));
  const tick = useReducer(() => ({}), {})[1];

  const timeToReload = FutureTimeToRoundedInterval(nextRefreshTime);
  const timeToReloadString = IntervalToString(timeToReload);
  const tickMS = Math.max(INTERVAL_UNIT_MAP[timeToReload.intervalUnit]/60, 500);

  const lastRefreshString = lastRefreshTime?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) ?? '--:--';

  const handleFetch = useCallback(
    async (
      query: Partial<IORGDataTableQueryState>,
      options?: IORGDataTableUpdateOptions
    ) => {
      if (!options || (options && options.action) || !state.newList.length) {
        setLoading(true);
      }

      setParams(query);
      const data = {
        ...query,
        all: hasRole(AccessRole.MCC_OUTAGE_SCHEDULER),
        filters: getLerRequestNewFilters(query.filters),
      };

      await actions.listItemGET(data);

      const currentTime = new Date();
      setLastRefreshTime(currentTime);
      setNextRefreshTime(new Date(currentTime.getTime() + intervalMS));

      setLoading(false);
    },
    [actions, setParams, setLoading, intervalMS]
  );

  // This will refresh the list if the detail/edit page is closed
  useEffect(() => {
    if (refresh && !lerView && !lerEdit) {
      const trigger = async () => {
        await handleFetch(params);

        setRefresh(false);
      };

      trigger();
    }
  }, [refresh, lerView, lerEdit, params, setRefresh, handleFetch]);

  useEffect(() => {
    if (lerView || lerEdit) {
      setRefresh(true);
    }
  }, [lerView, lerEdit, setRefresh]);

  // Refresh the list on an interval
  useEffect(() => {
    const interval = setInterval(() => {
      if (nextRefreshTime.getTime() <= new Date().getTime()) {
        handleFetch(params);
      }
      tick();
    }, tickMS);

    return () => clearInterval(interval);
  }, [tickMS, nextRefreshTime, handleFetch, params, tick]);

  // US #508370 Sorting
  const columns: IORGDataTableColumn<ILerRequestItem>[] = [
    {
      index: 'requestId',
      title: Lang.LBL_LER,
      render: (value, values) => (
        <Link
          to={getUrl(
            hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) && hasEditAccess(values)
              ? { lerEdit: value }
              : { lerView: value }
          )}
        >
          {value}
        </Link>
      ),
    },
    {
      index: 'requestStat',
      title: 'Request Status',
      render: (_, value) => <OutageStatus data={value} />,
    },
    {
      index: 'changeReqStat',
      title: 'CR Status',
      render: (_, value) =>
        value.changeReqStat && (
          <ATMLabel className={styles.labelTransparent}>
            {value.changeReqStat}
          </ATMLabel>
        ),
    },
    {
      index: 'outageFacility.outageType.outageTypeDesc',
      title: Lang.LBL_AUTHORIZATION_TYPE,
      render: (_, value) => value.outageFacility?.outageType?.outageTypeDesc,
    },
    // {
    //   index: `${LERRequestFormStep.OUTAGE_FACILITY}.outageFacility.authorization`,
    //   title: Lang.LBL_AUTHORIZATION_TYPE,
    //   render: (_, value) => (
    //     <span>{value.outageFacility.outgTypId ? 'OK' : 'NA'}</span>
    //   ),
    // },
    {
      index: 'requestorName',
      title: Lang.LBL_REQUESTOR_NAME,
      render: (_, value) =>
        value.requestor ? getEmployeeName(value.requestor) : null,
    },
    {
      index: 'actionPendingBy',
      sortable: false,
      title: Lang.LBL_ACTION_PENDING_BY,
      // isVisible: !hasRole(AccessRole.MCC_OUTAGE_SCHEDULER),
      render: (_, value) => {
        let other = '-';
        const employee = () => {
          let emp = value.requestor;

          if (hasRole(AccessRole.MCC_OUTAGE_REQUESTOR)) {
            if (
              value.requestStat === LERRequestStatus.Submitted &&
              !value.changeReqStat
            ) {
              emp = null;
              other = 'Outage Coordinator';
            }

            if (
              value.requestStat === LERRequestStatus.Scheduled &&
              !value.changeReqStat
            ) {
              emp = null;
            }

            if (
              value.requestStat === LERRequestStatus.Created &&
              !value.changeReqStat
            ) {
              emp = value.supervisor;
            }
          }

          if (hasRole(AccessRole.MCC_SUBMITTING_SUPERVISOR)) {
            if (
              value.requestStat === LERRequestStatus.Submitted &&
              value.changeReqStat === LERRequestChangeStatus.Requested
            ) {
              emp = value.requestor;
            }
            if (
              value.requestStat === LERRequestStatus.Created &&
              !value.changeReqStat
            ) {
              emp = value.supervisor;
            }
            if (
              value.requestStat === LERRequestStatus.Submitted &&
              !value.changeReqStat
            ) {
              emp = null;
              other = 'Outage Coordinator';
            }
            if (
              value.requestStat === LERRequestStatus.Scheduled &&
              !value.changeReqStat
            ) {
              emp = null;
            }
            if (
              value.requestStat === LERRequestStatus.Scheduled &&
              value.changeReqStat === LERRequestChangeStatus.Submitted
            ) {
              emp = null;
              other = 'Outage Coordinator';
            }
          }

          if (hasRole(AccessRole.MCC_OUTAGE_SCHEDULER)) {
            if (
              value.requestStat === LERRequestStatus.Submitted &&
              !value.changeReqStat
            ) {
              emp = null;
              other = 'Outage Coordinator';
            }
          }
          return emp;
        };
        return <span>{employee() ? getEmployeeName(employee()) : other}</span>;
      },
    },
    {
      index: 'outageFacility.facility.outgFacNm',
      title: 'Facility Name',
      render: (_, value) => value.outageFacility?.facility?.outgFacNm,
    },
    {
      index: 'substation',
      title: 'Substation',
      render: (_, value) =>
        value.outageFacility?.facility?.facTypId !==
          Lang.FACILITY_TYPE_ID_BACKEND.LINE && value.outageFacility?.substation
          ? value.outageFacility?.substation?.name
          : '',
    },
    {
      index: 'startDate',
      title: 'Start Date',
      render: (_, value) => getLerRequestStartDate(value.outageDates),
    },
    {
      index: 'endDate',
      title: 'End Date',
      render: (_, value) => getLerRequestEndDate(value.outageDates),
    },
    {
      index: 'days',
      title: 'Days',
      sortable: false,
      render: (value, values) => <OutageDay day={value} data={values} />,
    },
    {
      index: 'emailGroups',
      title: 'Pending Email',
      sortable: false,
      render: (_, value) => {
        if (value.emailGroups && value.emailGroups.length > 0) {
          return value.emailGroups.some(
            (v) => v.status === LERNotificationStatus.PENDING
          ) ? (
            <ATMIcon name="exclamation circle" color="orange" />
          ) : (
            <ATMIcon name="check circle" color="green" />
          );
        }

        return null;
      },
    },
    {
      index: 'assignedTo',
      title: 'Assigned to',
      sortable: false,
      render: (_, value) => (
        <LERRequestAssignScheduler
          requestId={value.requestId}
          employee={value.schedulerAssign || undefined}
          handleSuccess={() => {
            handleFetch(params);
          }}
        />
      ),
    },
  ];

  return (
    <ORGDataTable
      celled={false}
      columns={columns}
      sortable
      data={
        hasRole(AccessRole.MCC_OUTAGE_REQUESTOR)
          ? state.newList.filter(
              (val) =>
                !moment(getLerRequestEndDate(val.outageDates)).isBefore(
                  moment(new Date())
                )
            )
          : state.newList
      }
      onChange={handleFetch}
      loading={loading}
      total={
        hasRole(AccessRole.MCC_OUTAGE_REQUESTOR)
          ? state.newList.filter(
              (val) =>
                !moment(getLerRequestEndDate(val.outageDates)).isBefore(
                  moment(new Date())
                )
            ).length
          : state.newTotal
      }
      noDataText={<p>No New LERs created.</p>}
      counter
    >
      {() => ({
        toolbars: {
          caisoLabel: () => (
            <>
              <div>
                <b>Last Refresh</b> {lastRefreshString} | <b>Next refresh</b> in {timeToReloadString}
              </div>
              <QueueSummary />
            </>),
        },
      })}
    </ORGDataTable>
  );
};

export default LERRequestListNew;
