import React, { useCallback, useRef, useState } from 'react';
import { useContractorContext } from 'src/contexts/contractor.context';
import { contractorActionTypes } from 'src/ducks/contractor.duck';
import { IContractor, IContractorForm } from 'src/models/contractor.model';
import { getContractorStatus } from 'src/selectors/contractor.selector';
// import history from 'src/history';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import Lang from 'src/libraries/language';
import ContractorEditView from './contractor-edit.view';

type IProp = {
  data: IContractor;
};

const ContractorEdit: React.FC<IProp> = ({ data }) => {
  const { state, actions } = useContractorContext();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const status = getContractorStatus(
    state,
    contractorActionTypes.CONTRACTOR_DATA_UPDATE
  );
  const [isStatusChanged, setIsStatusChanged] = useState(false);

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IContractorForm) => {
      const response = await actions.updatePUT(data.empId, {
        ...formData,
        fullName: formData?.middleName
          ? `${formData.lastName} ${formData.firstName} ${formData?.middleName}`
          : `${formData.lastName} ${formData.firstName}`,
      });

      if (!response.error) {
        if (sessionStorage.getItem('contractors')) {
          actions.listGET(
            JSON.parse(sessionStorage.getItem('contractors') as string)
          );
        } else {
          actions.listGET({
            action: 'search',
            'filters[]': {
              name: 'fullName',
              value: response.payload?.fullName,
            },
            limit: 0,
          });
        }
        setIsEditOpen(false);

        ToastSuccess(
          Lang.formatString(
            Lang.MSG_CONTRACTOR_EDIT,
            response.payload?.fullName ?? '00000'
          )
        );
        // history.push('/auth-list?page=1&limit=25&tab=4');
      } else {
        ToastError('Error updating a contractor');
      }
    },
    [data, actions, setIsEditOpen]
  );

  return (
    <ContractorEditView
      formRef={formRef}
      defaultValues={data}
      loading={status.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      isOpen={isEditOpen}
      handleOpen={setIsEditOpen}
      isStatusChanged={isStatusChanged}
      setIsStatusChanged={setIsStatusChanged}
    />
  );
};

export default ContractorEdit;
