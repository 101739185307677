const IntervalUnits = ['hour', 'min', 's', 'ms'] as const;
export type IntervalUnit = typeof IntervalUnits[number];
export type Interval = {
  interval: number;
  intervalUnit: IntervalUnit;
}

export const INTERVAL_UNIT_MAP: Record<IntervalUnit, number> = {
  ms: 1,
  s: 1000,
  min: 60000,
  hour: 3600000,
}

const INTERVAL_LABEL_MAP: Record<IntervalUnit, string> = {
  ms: 'millisecond',
  s: 'second',
  min: 'minute',
  hour: 'hour',
}

export function FutureTimeToRoundedInterval(futureTime: Date, minInterval: IntervalUnit = 's'): Interval {
  const now = new Date();
  const eventTime = futureTime.getTime() - now.getTime();
  
  for (const unit of IntervalUnits) {
    const unitValue = INTERVAL_UNIT_MAP[unit];
    if (unitValue < INTERVAL_UNIT_MAP[minInterval]) {
      break;
    }
    if (eventTime < unitValue) {
      continue;
    }

    const interval = Math.round(eventTime / unitValue);
    return {
      interval,
      intervalUnit: unit,
    }
  }

  return {
    interval: 0,
    intervalUnit: minInterval,
  }
}

export const IntervalToString = (interval: Interval): string => {
  return `${interval.interval} ${INTERVAL_LABEL_MAP[interval.intervalUnit]}${interval.interval > 1 ? 's' : ''}`;
}