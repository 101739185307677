import React from 'react';
import classNames from 'classnames';
import { ATMIcon, ATMLabel, ATMPopover } from 'shared-it-appmod-ui';
import { LERNotificationStatus, LERRequestStatus } from 'src/constants';
import Lang from 'src/libraries/language';
import { ILerRequest, ILerRequestItem } from 'src/models/ler-request.model';
import { getEmployeeName } from 'src/helpers/employee.helper';
import { formatDateTime } from 'src/libraries/moment.library';
import styles from './outage-status.module.scss';
import FieldReadOnly from '../field/field-readonly/field-readonly.component';

type IProps = {
  language?: Record<string, string>;
  data?: ILerRequest | ILerRequestItem;
  label?: boolean;
  bold?: boolean;
};

const OutageStatus: React.FC<IProps> = ({
  language = Lang.LER_REQUEST_STATUS,
  data,
  label = true,
  bold = false,
  children,
}) => {
  if (!data) {
    return null;
  }

  let type = 'danger';

  let { requestStat } = data;

  if (!requestStat) {
    return null;
  }

  const emailGroups =
    (data as ILerRequestItem).emailGroups ??
    (data as ILerRequest).authorizationStatement?.emailGroups ??
    [];

  if (data && emailGroups.length) {
    const pending = emailGroups.some(
      (v) => v.status === LERNotificationStatus.PENDING
    );

    if (pending) {
      requestStat = LERRequestStatus.Pending;
    }
  }

  switch (requestStat) {
    case LERRequestStatus.Rejected: {
      type = 'danger';
      break;
    }

    case LERRequestStatus.Pending: {
      type = 'warning-yellow';
      break;
    }

    case LERRequestStatus.Scheduled: {
      type = 'scheduled';
      break;
    }

    case LERRequestStatus.Approved: {
      type = 'approved';
      break;
    }

    case LERRequestStatus.Study: {
      type = 'study';
      break;
    }

    case LERRequestStatus.Submitted: {
      type = 'submitted';
      break;
    }

    case LERRequestStatus.Created: {
      type = 'created';
      break;
    }

    case LERRequestStatus.In: {
      type = 'in';
      break;
    }
    case LERRequestStatus.Out: {
      type = 'out';
      break;
    }

    case LERRequestStatus.Completed: {
      type = 'completed';
      break;
    }

    case LERRequestStatus.Cancelled: {
      type = 'cancelled';
      break;
    }
  }

  const hasInfo =
    (!!data.cancelType || !!data.outageReject) &&
    [LERRequestStatus.Cancelled, LERRequestStatus.Rejected].includes(
      data.requestStat as LERRequestStatus
    );

  let component = (
    <span
      className={classNames(styles.status, styles[type], {
        [styles.bold]: bold,
      })}
    >
      {children ?? language[requestStat] ?? requestStat}
    </span>
  );

  if (label) {
    component = (
      <ATMLabel
        className={classNames(styles.label, styles[type], {
          [styles.pointer]: hasInfo,
        })}
      >
        {language[requestStat] ?? requestStat}{' '}
        {hasInfo && (
          <ATMIcon name="exclamation circle" className={styles.icon} />
        )}
      </ATMLabel>
    );
  }

  if (data.cancelType && data.requestStat === LERRequestStatus.Cancelled) {
    let cancelNote: ILerRequestItem['cancelNote'] = null;
    let requestor: ILerRequestItem['requestor'];
    const { cancelType, cancelor, cnclAt } = data;

    if ((data as ILerRequestItem).cancelNote) {
      cancelNote = (data as ILerRequestItem)?.cancelNote;
      requestor = (data as ILerRequestItem)?.requestor;
    } else {
      cancelNote =
        (data as ILerRequest).authorizationStatement?.cancelNote ?? null;
      requestor = (data as ILerRequest).crewInformation?.requestor;
    }

    return (
      <ATMPopover
        trigger={component}
        className={styles.popup}
        header="Cancellation Information"
        content={
          <div className={styles.popupContent}>
            <FieldReadOnly
              label="Requested By"
              value={getEmployeeName(requestor)}
            />
            <FieldReadOnly
              label="Cancelled By"
              value={getEmployeeName(cancelor)}
            />
            <FieldReadOnly
              label="Cancel Date/Time"
              value={formatDateTime(cnclAt)}
            />
            <FieldReadOnly
              label="Cancelled By (Role)"
              value={cancelType?.cancelTypeDesc}
            />
            <FieldReadOnly label="Cancel Reason" value={cancelNote?.noteTxt} />
          </div>
        }
        on="click"
        wide
      />
    );
  }

  if (data.outageReject && data.requestStat === LERRequestStatus.Rejected) {
    const { outageReject } = data;

    return (
      <ATMPopover
        trigger={component}
        className={styles.popup}
        header="Rejection Information"
        content={
          <div className={styles.popupContent}>
            <FieldReadOnly
              label="Rejector Name"
              value={getEmployeeName(outageReject.rejector)}
            />
            <FieldReadOnly
              label="Rejection Date"
              value={formatDateTime(outageReject.rejectionDate)}
            />
            <FieldReadOnly
              label="Rejection Reason"
              value={outageReject?.reason?.reasonDesc}
            />
            <FieldReadOnly
              label="Rejection Notes"
              value={outageReject.rejectionReason}
            />
          </div>
        }
        on="click"
        wide
      />
    );
  }

  return component;
};

export default OutageStatus;
