import React, { useCallback, useState } from 'react';
import LERRequestOutageDateForm from '../ler-request-outage-date-form/ler-request-outage-date-form.component';
import Moment, { IMoment } from 'src/libraries/moment.library';
import { FieldArrayWithId, useFieldArray } from 'react-hook-form';
import {
  ILerRequestForm,
  ILerRequestOutageDate,
  ILerRequestOutageFacilityListForm,
} from 'src/models/ler-request.model';
import { ATMButton, useATMFormContext } from 'shared-it-appmod-ui';
type IProps = {
  keyValue?: any;
  setClose?: () => void;
  fields?: FieldArrayWithId<ILerRequestOutageDate>[];
  handleAdd: (
    list: ILerRequestOutageFacilityListForm,
    callback: () => void
  ) => void;
  handleDelete: (key: string | string[]) => void;
  facilityBesInd?: string;
  hide?: boolean;
};

const LERRequestOutageDateEdit: React.FC<IProps> = ({
  hide,
  keyValue,
  facilityBesInd,
  // fields,
  handleAdd,
  handleDelete,
}) => {
  const { control } = useATMFormContext<ILerRequestForm>();
  const { fields } = useFieldArray({
    name: 'outageDates',
    control,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState<IMoment>();
  const onClose = useCallback(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [isOpen, setIsOpen]);

  const handleDate = useCallback(
    (keys: string | string[]) => {
      const keyList = !Array.isArray(keys) ? [keys] : keys;
      const key = keyList
        ?.flat()
        ?.filter((key) => fields?.find((v) => v?.key === key));
      const filteredDates = fields?.filter((val) =>
        key.map((val) => val).includes(val.key)
      );
      filteredDates?.length &&
        setActive(Moment.min(filteredDates.map((v) => Moment(v.startTm))));
      setIsOpen(true);
    },
    [fields, active, setIsOpen, setActive]
  );

  if (hide) {
    return null;
  }

  return (
    <div>
      <ATMButton
        icon="edit"
        type="button"
        onClick={() => handleDate(keyValue)}
      />
      {active && isOpen ? (
        <LERRequestOutageDateForm
          fields={fields ? fields : []}
          handleAdd={handleAdd}
          handleDelete={handleDelete}
          onClose={onClose}
          date={active}
          multiple={false}
          facilityBesInd={facilityBesInd as any}
          isEditContinuous
        />
      ) : null}
    </div>
  );
};

export default LERRequestOutageDateEdit;
