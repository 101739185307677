import React, { useEffect, useCallback } from 'react';
import { useAuthorizedListContext } from 'src/contexts/authorized-list.context';
import { getAuthorizedListStatus } from 'src/selectors/authorized-list.selector';
import {
  buildExportDataWithTableTitle,
  convertToCSV,
} from 'src/selectors/file.selector';
import { authorizedListActionTypes } from 'src/ducks/authorized-list.duck';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import Moment from 'src/libraries/moment.library';
import {
  IAuthorizedList,
  IAuthorizedListTraining,
} from 'src/models/authorized-list.model';
import { ISubCategory } from 'src/models/sub-category.model';
import { checkValue } from 'src/libraries/common.library';
import Lang from 'src/libraries/language';
import TrainingHistoryListView from './training-history.view';

type IProp = {
  data?: IAuthorizedList;
  trainingData?: any;
  subCategoryList: ISubCategory[];
  subCategoryStatus: boolean;
};

const TrainingHistoryList: React.FC<IProp> = ({
  data,
  subCategoryList,
  subCategoryStatus,
}) => {
  const { state, actions } = useAuthorizedListContext();
  const {
    params: { id },
  } = useLocationParams(['id']);
  const status = getAuthorizedListStatus(
    state,
    authorizedListActionTypes.AUTHORIZED_LIST_DATA_BY_EMP_ID_READ
  );
  useEffect(() => {
    actions.empDataGET(id);
  }, [actions]);

  const handleFetch = useCallback(() => {
    actions.empDataGET(id);
  }, [actions]);

  const handleDownload = useCallback(async () => {
    const result = await actions.empDataGET(data?.empId);
    if (result.payload) {
      const list = data
        ? (data?.trainings as IAuthorizedListTraining[])
        : (state?.data?.trainings as IAuthorizedListTraining[]);
      const employeeInfo = `${data?.fullName} / ${
        data?.empId ? data?.empId : data?.contracShortNm
      }`;
      const items = list.map((value) => [
        checkValue(value.authTypId),
        checkValue(Moment(value.trainingDate).format('YYYY-MM-DD') ?? '--'),
        checkValue(
          value.expiryDate?.includes('9999') ? '--' : value.expiryDate
        ),
        checkValue(value.contracShortNm ?? '--'),
        checkValue(value.subcategory?.description ?? '--'),
        checkValue(value.trainerName ?? '--'),
        checkValue(!value.testFailInd ? 'No' : 'Yes'),
      ]);
      const { exportData, format } = convertToCSV(
        buildExportDataWithTableTitle(
          items,
          [
            Lang.LBL_AUTH_TYPE,
            Lang.LBL_TRAINING_DATE,
            Lang.LBL_TRAINING_EXPIRATION_DATE,
            Lang.LBL_FIRM_NAME,
            Lang.LBL_SUBCATEGORY,
            Lang.LBL_TRAINER,
            Lang.LBL_FAILED_TEST,
          ],
          employeeInfo
        )
      );
      const link = document.createElement('a');
      link.setAttribute('href', exportData);
      link.setAttribute(
        'download',
        `MCC_Training_History_${data?.fullName}_${Moment().format(
          'YYYYMMDD'
        )}.${format}`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [actions, state]);

  return (
    <TrainingHistoryListView
      data={data}
      handleFetch={handleFetch}
      loading={status.fetching}
      subCategoryStatus={subCategoryStatus}
      handleDownload={handleDownload}
      subCategoryList={subCategoryList}
    />
  );
};

export default TrainingHistoryList;
