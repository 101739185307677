import yup from 'src/libraries/validator.library';

export const ReasonShape = {
  reasonId: yup.number().required(),
  reasonType: yup.string().required(),
  reasonDesc: yup.string().required(),
  activeFlag: yup.boolean().required(),
  updatedAt: yup.date().required(),
  updatedBy: yup.string().required(),
};

export const ReasonSchema = yup.object(ReasonShape);

export const ReasonFormSchema = yup.object({
  reasonDesc: yup.string().required().default(''),
});

export const ReasonListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(ReasonSchema.clone()),
});

export const ReasonPayloadSchema = yup.object({
  reason: ReasonSchema.clone(),
});

export const ReasonDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type IReason = yup.Asserts<typeof ReasonSchema>;
export type IReasonForm = yup.Asserts<typeof ReasonFormSchema>;
